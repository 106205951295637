const email = '/assets/ivergrama/General/Ivergrama-Website-Email-Icon.svg';
// const PopupIcon = '/assets/ivergrama/gallery/gallery5.jpg';
const BookingActive =
  '/assets/ivergrama/Home/Ivergrama-Website-Home-SearchBar-BuyTicket.svg';
const CancelActive =
  '/assets/ivergrama/Home/Ivergrama-Website-Home-SearchBar-CancelTicket.svg';
const OriginIcon =
  '/assets/ivergrama/Home/Ivergrama-Website-Home-SearchBar-Origin.svg';
const DestinationIcon =
  '/assets/ivergrama/Home/Ivergrama-Website-Home-SearchBar-Destination.svg';
const CalendarIcon =
  '/assets/ivergrama/Home/Ivergrama-Website-Home-SearchBar-Calendar.svg';
// const SearchIcon = '/assets/ivergrama/Home/Ivergrama-Website-Home-SearchBar-SearchIcon.svg';
const titleUnderline = '/assets/ivergrama/General/Ivergrama-Website-Titles.svg';

const BG =
  '/assets/ivergrama/Home/Ivergrama-Website-Slides-MobileVersion-2.jpg';

const sendBtn = '/assets/ivergrama/Home/Ivergrama-Website-Home-SendContact.svg';
const contactFormImg =
  '/assets/ivergrama/Home/Ivergrama-Website-Home-BannerSpecialServices.jpg';

const fb =
  '/assets/ivergrama/General/Ivergrama-Website-Footer-SocialMedia-Facebook.svg';
const fbHover =
  '/assets/ivergrama/General/Ivergrama-Website-Footer-SocialMedia-Facebook-hover.svg';
const twitter =
  '/assets/ivergrama/General/Ivergrama-Website-Footer-SocialMedia-Twitter.svg';
const twitterHover =
  '/assets/ivergrama/General/Ivergrama-Website-Footer-SocialMedia-Twitter-Hover.svg';

const LocationIcon = '/assets/ivergrama/General/LocationIcon.svg';
const PhoneIcon = '/assets/ivergrama/General/PhoneIcon.svg';
const HeaderLogo = '/assets/ivergrama/General/Header-BusesTepualLogo.svg';
const FooterLogo = '/assets/ivergrama/General/Footer-BusesTepualLogo.svg';
const checkboxIcon = '/assets/tepual/checkbox.png';

const BottomFooterKuposLogo = '/assets/tepual/Footer-kuposLogo.svg';
const animation =
  '/assets/animation-screen/Services-kupos-PasajeBus-Orange.gif';
const pnr =
  '/assets/ivergrama/General/Ivergrama-Website-Home-WhereToFindMyPNR.svg';

const contactMail =
  '/assets/ivergrama/Contact/Ivergrama-Website-Contact-EmailIcon.svg';
const contactAddress =
  '/assets/ivergrama/Contact/Ivergrama-Website-Contact-LocationIcon.svg';

const aboutUsImage =
  '/assets/ivergrama/aboutUs/Ivergrama-Website-AboutUs-HistoryImage.jpg';
// banners
const destinationBanner = '/assets/ivergrama/destinations/destination-main.jpg';
const serviceBanner =
  '/assets/ivergrama/Services/Ivergrama-Website-Services-MainBanner.jpg';
const contactUsBanner =
  '/assets/ivergrama/Contact/Ivergrama-Website-Contact-MainBanner.jpg';
const aboutUsBanner =
  '/assets/ivergrama/aboutUs/Ivergrama-Website-AboutUs-MainBanner.jpg';
const tncBanner =
  '/assets/ivergrama/tnc/Ivergrama-Website-TermsAndConditions-MainBanner.jpg';
const officeBanner = '/assets/ivergrama/offices/offices-banner.jpg';

const OfficePhoneIcon = '/assets/ivergrama/General/officePhone.svg';


// accounts section
const pay = '/assets/blue/Andipay.png';
const backimage = '/assets/blue/Andimar-BackgroundMobile.png';

const tarjetas = '/assets/orange/MyAccount-IllustratedIcons-MyCards-Orange.svg';
const viajes = '/assets/orange/MyAccount-IllustratedIcons-MyTrips-Orange.svg';
const movimientos = '../../../assets/carpool/pigi-bank-circle.png'; 
const wallet = '/assets/orange/MyAccount-IllustratedIcons-RechargeWallet-Orange.svg';
const shareMoney = '/assets/orange/MyAccount-IllustratedIcons-ShareMoney-Orange.svg';
const camera = '/assets/carpool/camera-add-circle.png';
const checkmark = '/assets/orange/IllustratedIcons-CheckMark-Blue.png';
const tick = '/assets/orange/IllustratedIcons-Tick-Blue.png';
const downArrow = '/assets/orange/IllustratedIcons-DropDown-Blue.png';
const loginblue = '../assets/carpool/login-circle.png';
const recoverpswd = '../../assets/carpool/forgot-circle.png';
const success = '/assets/orange/MyAccount-IllustratedIcons-CheckMark-Orange.svg';
const failure = '/assets/orange/MyAccount-IllustratedIcons-Information-Orange.svg';
const warning = '/assets/orange/MyAccount-IllustratedIcons-Information-Orange.svg';
const userIcon = '../assets/carpool/user-circle.png';
const heartOn = '../assets/icons/heart.png';
const heartOff = '../assets/icons/heart-empty.png';
const registrationSuccess = '/assets/carpool/registration-done-circle.png';

 //  pet seat 
const petseatref = '/assets/ivergrama/pet/Ivergrama-ResultPage-SeatPick-PetsOnBoard_Pet Paw Icon (Amenite).svg';
const petseatModal = '/assets/ivergrama/pet/PopUp.png';
const petfilteroff = '/assets/ivergrama/pet/Ivergrama-ResultPage-PetsOnBoard-Button-Filter_off.svg';
const petfilteron = '/assets/ivergrama/pet/Ivergrama-ResultPage-PetsOnBoard-Button-Filter_On.svg';

const operatorPay = '/assets/ivergrama/iverpay.png'

const ivergramaIcons = [
  { icon: PhoneIcon, name: 'phone' },
  { icon: email, name: 'email' },
  { icon: LocationIcon, name: 'location' },
  { icon: HeaderLogo, name: 'headerLogo' },
  { icon: FooterLogo, name: 'footerLogo' },
  { icon: contactFormImg, name: 'contactFormImg' },
  { icon: titleUnderline, name: 'titleUnderline' },

  { icon: sendBtn, name: 'sendBtn' },
  { icon: fb, name: 'fb' },
  { icon: fbHover, name: 'fbHover' },
  { icon: twitter, name: 'twitter' },
  { icon: twitterHover, name: 'twitterHover' },
  { icon: BG, name: 'BG' },

  { icon: BookingActive, name: 'BA' },
  { icon: CancelActive, name: 'CA' },
  { icon: OriginIcon, name: 'Origin' },
  { icon: DestinationIcon, name: 'Destination' },
  { icon: CalendarIcon, name: 'Calendar' },
  // { icon: SearchIcon, name: 'Search' },
  // { icon: PopupIcon, name: 'Popup' },
  { icon: checkboxIcon, name: 'Checkbox' },
  { icon: BottomFooterKuposLogo, name: 'BottomFooterKuposLogo' },
  { icon: animation, name: 'animation' },
  { icon: pnr, name: 'pnr' },
  { icon: OfficePhoneIcon, name: 'OfficePhoneIcon' },

  { icon: contactMail, name: 'contactMail' },
  { icon: contactAddress, name: 'contactAddress' },
  { icon: destinationBanner, name: 'destinationBanner' },
  { icon: serviceBanner, name: 'serviceBanner' },
  { icon: contactUsBanner, name: 'contactUsBanner' },
  { icon: aboutUsImage, name: 'aboutUsImage' },
  { icon: aboutUsBanner, name: 'aboutUsBanner' },
  { icon: tncBanner, name: 'tncBanner' },
  { icon: officeBanner, name: 'officeBanner' },

  // accounts section
  { icon: pay, name: 'pay' },
  { icon: backimage, name: 'backimage' },
  { icon: tarjetas, name: 'tarjetas' },
  { icon: viajes, name: 'viajes' },
  { icon: movimientos, name: 'movimientos' },
  { icon: wallet, name: 'wallet' },
  { icon: shareMoney, name: 'shareMoney' },
  { icon: camera, name: 'camera' },
  { icon: checkmark, name: 'checkmark' },
  { icon: tick, name: 'tick' },
  { icon: downArrow, name: 'downArrow' },
  { icon: loginblue, name: 'loginblue' },
  { icon: recoverpswd, name: 'recoverpswd' },
  { icon: success, name: 'success' },
  { icon: failure, name: 'failure' },
  { icon: heartOn, name: 'heartOn' },
  { icon: heartOff, name: 'heartOff' },
  { icon: registrationSuccess, name: 'registrationSuccess' },
  { icon: userIcon, name: 'userIcon' },
  { icon: warning, name: 'warning' },

   //  pet seat 
   { icon: petseatref, name: 'petseatref' },
   { icon: petseatModal, name: 'petseatModal' },
   { icon: petfilteron, name: 'petfilteron' },
   { icon: petfilteroff, name: 'petfilteroff' },

  { icon: operatorPay, name: 'operatorPay' },

];

export default ivergramaIcons;
