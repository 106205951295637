import "./Theme11Contact.css";

import * as actions from '../../redux/modules/data/actions';

import { AppData, CommonService } from "../../services";
import { CarpoolSuccessFailureModal, PBInput, PButton } from "../../components";
import { IonContent, IonPage } from "@ionic/react";
import React, { useState } from "react";

import KuposHeader from "../../components/KuposHeader";
import { bindActionCreators } from "redux";
import { connectData } from "../../redux";
import getIcons from "../functions/getIcons/getIcons";

const initialState = {
			name: null,
			nameError: null, 
			email: null,
			emailError: null,
			affairs: null,
			affairsError: null,
			comment: null,
			commentError: null,
		};
const CentropuertoContactPage = ({ props, actions , history, operator}) => {

	const op = JSON.parse(localStorage.getItem("op")) || props.data.operator;
	
	const style = op?.color_codes
  ? `.primary-text {
		color: ${op?.color_codes?.primary_color};
		// margin: 0;
		// margin-bottom: 10px
	 }`
  : "";
	const [state, setState] = useState(initialState)
	const validate = () => {

		let errorCount = 0;
		if (!state.name) {
				setState({
						...state,
						affairsError: true,
						affairsErrorMessage: "Ingresa tu nombre",
				});
				errorCount++;
		} else if (state.affairsError) {
				setState({
						...state,
						phoneError: true,
						phoneErrorMessage: "Ingresa tu número",
				});
				errorCount++;
		} else if (!state.email) {
				setState({
						...state,
						emailError: true,
						emailErrorMessage: "Ingrese un email valido",
				});
				errorCount++;
		} else if (state.email && !CommonService.isEMailValid(state.email)) {
				setState({
						...state,
						emailError: true,
						emailErrorMessage: "Ingrese un email valido",
				});
				errorCount++;
		} else if (!state.comment) {
				setState({
						...state,
						commentError: true,
						commentErrorMessage: "Inserta tu comentario",
				});
				errorCount++;
		}

		if (errorCount > 0) {
				return false;
		} else {
				return true;
		}
};
const onSubmit = () => {
		if (!validate()) {
				return;
		}

		let data = {
				name: state.name,
	
				email: state.email,
				affairs: state.affairs,
				comments: state.comment,
				operator_id: op.operator_id,
				operator_name: op.operator_name,
		};

				props.opsiteContactInfo({
						callback: (result) => onContactInfoResponse(result),
						data: data,
				});
		
};
const onContactInfoResponse = (res) => {
		console.log("response from contact info", res);
		if (res && res.data && res.data.message) {
				setState({
						...state,
						showModal: true,
						responseMessage: res.data.message,
						status: res.data.status,
				});
		}
};



const onChange = (value, type) => {
	if (type == 'mobile') {
			value = CommonService.phoneNumberFormat(value);
			setState({
					...state,
					[type]: value,
					[type + 'Error']: false,
			});
	} else {
			setState({ ...state, [type]: value, [type + 'Error']: false });
	}
};
  return (
    <IonPage>
      <IonContent>
						<div className="text-center">
								<KuposHeader
            // data={props.data}
												boldTitle={"       Contacto"}
            handleHomeSubmit={() => props.history.push("/")}
            handleBackSubmit={() => props.history.goBack()}
          />
										</div>
        <style>{style}</style>
								<div className="themes-theme-eleven-faq new-container" >
						

		      	<div className="new-container-compact contact-page-mweb mb15 mt10 ">
          
										  <div class="contact-icons-set mobile-contact-icon-set">
												<div class="contact-icon d-flex font14">
													<div>  <img alt="white-arrow" src={getIcons('PhoneYellowIcon', op)} /></div>
              <div>    <p class="text-bold">Teléfono:</p>
            <p>	(+562) 2601 9883 / (+562) 2601 0549</p></div>
											
          </div>
          <div class="contact-icon d-flex font14">
											<div><img alt="white-arrow" src={getIcons('EmailYellowIcon', op)} /></div>
            <div>     <p class="text-bold ">Correo:</p>
            <p >info@centropuerto.cl </p></div>
       
          </div>
       
            </div>
      
											<div className="card form-card">
												<PBInput
															label="Nombre y apellido"
															className="kupos-border"
															type="text"
															placeholder="Nombre y apellido"
															value={state.name}
															onChange={value => onChange(value, 'name')}
															error={!!state.nameError}
															errorMessage={state.nameErrorMessage}
													/>
												<PBInput
															label="Email"
															className="kupos-border"
															type="text"
															placeholder="example@gmail.com"
															value={state.email}
															onChange={value => onChange(value, 'email')}
															error={!!state.emailError}
															errorMessage={state.emailErrorMessage}
													/>

												<PBInput
															label="Asunto"
															className="kupos-border"
															type="text"
															placeholder="Asunto"
															value={state.affairs}
															onChange={value => onChange(value, 'affairs')}
															error={!!state.affairsError}
															errorMessage={state.affairsErrorMessage}
													/>
												<PBInput
															label="Mensaje"
															className="kupos-border"
															type="text"
															placeholder="Mensaje"
															value={state.comment}
															onChange={value => onChange(value, 'comment')}
															error={!!state.commentError}
															errorMessage={state.commentErrorMessage}
													/>

													<div className="center-submit mt-20 font10" > <PButton
                title={"ENVIAR"}
                operator={operator}
                onPress={onSubmit}/>
															{/* <PButton title={'ENVIAR'} onPress={onSubmit} /> */}
													
													</div>
												 <CarpoolSuccessFailureModal
															showModal={state.showModal}
															success={state.status ? true : false}
															smallIcon={true}
															bodyText={state.responseMessage}
															buttonText={"OK"}
															onButtonPress={() => {
															setState({
																	showModal: false,
															});
															props.history.push("/");
															
															}}
      							/> 
											</div>
											</div>
										
								</div>
      </IonContent>
    </IonPage>
  );
};


export default connectData()(CentropuertoContactPage);
