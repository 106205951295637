import "./ThemeTwoContactForm.css";

import {
    CarpoolSuccessFailureModal,
    PBInput,
    PButton,
} from "../../../components";
import React, { useState } from "react";

import { CommonService } from "../../../services";
import { connectData } from "../../../redux";
import getIcons from "../../functions/getIcons/getIcons";

const ThemeTwoContactForm = (props) => {
    const initialState = {
        name: null,
        phone: null,
        email: null,
        comment: null,
        nameError: false,
        phoneError: false,
        emailError: false,
        commentError: false,
    };
    const [state, setState] = useState(initialState);

    const operator =
        JSON.parse(localStorage.getItem("op")) || props.data.operator;

    const inpChange = (value, type) => {
        if (type == "phone") {
            value = CommonService.phoneNumberFormat(value);
            setState({
                ...state,
                [type]: value,
                [type + "Error"]: false,
            });
        } else {
            setState({ ...state, [type]: value, [type + "Error"]: false });
        }
    };

    const validate = () => {
        let errorCount = 0;
        if (!state.name) {
            setState({
                ...state,
                nameError: true,
                nameErrorMessage: "Ingresa tu nombre",
            });
            errorCount++;
        } else if (!state.phone) {
            setState({
                ...state,
                phoneError: true,
                phoneErrorMessage: "Ingresa tu número de teléfono",
            });
            errorCount++;
        } else if (!state.email) {
            setState({
                ...state,
                emailError: true,
                emailErrorMessage: "Ingrese un email valido",
            });
            errorCount++;
        } else if (state.email && !CommonService.isEMailValid(state.email)) {
            setState({
                ...state,
                emailError: true,
                emailErrorMessage: "Ingrese un email valido",
            });
            errorCount++;
        } else if (!state.comment) {
            setState({
                ...state,
                commentError: true,
                commentErrorMessage: "Inserta tu comentario",
            });
            errorCount++;
        }

        if (errorCount > 0) {
            return false;
        } else {
            return true;
        }
    };

    const onSubmit = () => {
        if (!validate()) {
            return;
        }

        let data = {
            name: state.name,
            email: state.email,
            phone_number: state.phone,
            comments: state.comment,
            operator_id: operator.operator_id,
            operator_name: operator.operator_name,
        };

        const operatorsRequiringLastName = []; // add operator name in Array for which you need last_name.

        if (operatorsRequiringLastName.includes(operator.operator_name) && state.last_name) {
            data.last_name = state.last_name;
        }
        if (operator && (operator.operator_name === "tarapaca" || operator.operator_name === "santamaria" || operator.operator_name === "kennybus" || operator.operator_name === "barriaghisoni" || operator.operator_name === "cormarbus" || operator.operator_name === "serenamar" || operator.operator_name === "jetsur" || operator.operator_name === "viatur" || operator.operator_name === "combarbala" || operator.operator_name === "expresosantacruz" || operator.operator_name === "biaggini" || operator.operator_name === "thaebus")) {

            // if (operator && (operator.operator_name === "tarapaca" || operator.operator_name === "santamaria" || operator.operator_name === "biaggini")) {

            props.opsiteContactInfo({
                callback: (result) => onContactInfoResponse(result),
                data: data,
            });
        }
    };


    const onContactInfoResponse = (res) => {
        if (res && res.data && res.data.message) {
            setState({
                ...state,
                showModal: true,
                responseMessage: res.data.message,
                status: res.data.status,
            });
        }
    };

    const renderBiagginiInfo = () => (
        <div className="info">
            {operator.phone_number && (
                <div className="info-item bold-text">
                    <img src={getIcons("Headphone", operator)} alt="" />
                    <div className="info-text">Teléfono: {operator.phone_number}</div>
                </div>
            )}
            {operator.email && (
                <div className="info-item bold-text">
                    <img src={getIcons("Whatsapp", operator)} alt="" />
                    <span className="info-text">Whatsapp: +56 9 4915 6683</span>
                </div>
            )}
            {operator.email && (
                <div className="info-item bold-text">
                    <img src={getIcons("Email", operator)} alt="" />
                    <span className="info-text">Email: {operator.email}</span>
                </div>
            )}
        </div>
    );

    return (
        operator && (
            <div className="ThemeTwoContactForm bold-text">
                <div className="new-container ">
                    <div className="info">
                        {(operator && operator.operator_name === "kennybus") ||
                            (operator && operator.operator_name === "tarapaca") ||
                            (operator && operator.operator_name === "serenamar") ? (
                            <React.Fragment>
                                {operator.phone_number ? (
                                    <div className="info-item">
                                        <img src={getIcons("Headphone")} alt="" />
                                        <span className="info-text">
                                            Telefono: {operator.phone_number}
                                        </span>
                                    </div>
                                ) : null}

                                <div className="info-item">
                                    <img src={getIcons("Whatsapp")} alt="" />
                                    <span className="info-text">+56 9 4915 6683</span>
                                </div>

                                {operator.email ? (
                                    <div className="info-item">
                                        <img src={getIcons("Email")} alt="" />
                                        <span className="info-text">Email: {operator.email}</span>
                                    </div>
                                ) : null}
                            </React.Fragment>
                        ) : (operator && operator.operator_name === "jetsur") ||
                            operator.operator_name === "thaebus" ? (
                            <React.Fragment>
                                {operator.phone_number ? (
                                    <div className="info-item">
                                        <img src={getIcons("Headphone")} alt="" />
                                        <span className="info-text">
                                            Telefono: {operator.phone_number}
                                        </span>
                                    </div>
                                ) : null}

                                {operator.address ? (
                                    <div className="info-item">
                                        <img src={getIcons("address")} alt="" />
                                        <span className="info-text">
                                            <span
                                                className="bold-text"
                                                style={{
                                                    marginBottom: "10px",
                                                    marginTop: "0",
                                                }}
                                            >
                                                Casa matriz:
                                            </span>
                                            {operator.address}
                                        </span>
                                    </div>
                                ) : null}

                                {operator.email ? (
                                    <div className="info-item">
                                        <img src={getIcons("Email")} alt="" />
                                        <span className="info-text">Email: {operator.email}</span>
                                    </div>
                                ) : null}
                            </React.Fragment>
                        ) : operator && operator.operator_name === "barriaghisoni" ? (
                            <React.Fragment>
                                {operator && operator.phone_number ? (
                                    <div className="info-item bold-text">
                                        <img src={getIcons("Headphone", operator)} alt="" />
                                        <div className="info-text">Teléfono:&nbsp;</div>
                                        <div className="info-text">{operator.phone_number}</div>
                                    </div>
                                ) : null}
                                {operator && operator.address ? (
                                    <div className="info-item bold-text">
                                        <img src={getIcons("address", operator)} alt="" />
                                        <span className="info-text">
                                            Casa matriz: {operator.address}
                                        </span>
                                    </div>
                                ) : null}
                                {operator && operator.email ? (
                                    <div className="info-item bold-text">
                                        <img src={getIcons("Email", operator)} alt="" />
                                        <span className="info-text">Email: {operator.email}</span>
                                    </div>
                                ) : null}
                            </React.Fragment>
                        ) : (operator && operator.operator_name === "combarbala") ||
                            operator.operator_name === "expresosantacruz" ? (
                            <React.Fragment>
                                {operator && operator.phone_number ? (
                                    <div className="info-item bold-text">
                                        <img src={getIcons("Headphone", operator)} alt="" />
                                        <div className="info-text">Teléfono:&nbsp;</div>
                                        <div className="info-text">
                                            {operator.phone_number.split("/")[0]}
                                        </div>
                                    </div>
                                ) : null}
                                <div className="info-item">
                                    <img src={getIcons("Whatsapp")} alt="" />
                                    <span className="info-text">+56 9 5683 7965</span>
                                </div>
                                {operator && operator.email ? (
                                    <div className="info-item bold-text">
                                        <img src={getIcons("Email", operator)} alt="" />
                                        <span className="info-text">Email: {operator.email}</span>
                                    </div>
                                ) : null}
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                {operator && operator.address ? (
                                    <div
                                        className="info-item"
                                        style={{
                                            alignItems: "flex-start",
                                        }}
                                    >
                                        <img src={getIcons("office", operator)} alt="" />
                                        <span
                                            className="info-text"
                                            style={{
                                                color:
                                                    operator &&
                                                        operator.color_codes &&
                                                        operator.color_codes.tertiary_text_color
                                                        ? operator.color_codes.tertiary_text_color
                                                        : "",
                                            }}
                                        >
                                            <p
                                                className="bold-text"
                                                style={{
                                                    marginBottom: "10px",
                                                    marginTop: "0",
                                                }}
                                            >
                                                Casa matriz:
                                            </p>
                                            <div
                                                className="normal-text"
                                                style={{
                                                    color:
                                                        operator &&
                                                            operator.color_codes &&
                                                            operator.color_codes.tertiary_text_color
                                                            ? operator.color_codes.tertiary_text_color
                                                            : "",
                                                }}
                                            >
                                                {operator && operator.address}
                                            </div>
                                        </span>
                                    </div>
                                ) : null}
                                {/* Old Start */}

                                {/* <div
                  className="info-item"
                  style={{
                    alignItems: "flex-start",
                  }}
                >
                  <img src={getIcons("Headphone", operator)} alt="" />
                  <span
                    className="info-text"
                    style={{
                      color:
                        operator &&
                        operator.color_codes &&
                        operator.color_codes.tertiary_text_color
                          ? operator.color_codes.tertiary_text_color
                          : "",
                    }}
                  >
                    <p className="bold-text" style={{ margin: 0 }}>
                      Atención ventas en línea:
                    </p>
                    <p className="normal-text" style={{ margin: 0 }}>
                      {operator && operator.email}
                    </p>
                    <p className="normal-text" style={{ margin: 0 }}>
                      {operator && operator.phone_number}
                    </p>
                  </span>
                </div> */}

                                {/* Old End */}


                                {/* <div>
									{
										operator && operator.operator_name === "santamaria" ? (
											<React.Fragment>
												{operator.phone_number ? (
													<div className="info-item santamaria-info-item">
														<img src={getIcons("Headphone", operator)} alt="" />
														<span className="info-text santamaria-info-text">
															<p className="normal-text" style={{ margin: 0 }}>
																Teléfono: {operator.phone_number}
															</p>
														</span>
													</div>
												) : null}
												{operator.email ? (
													<div className="info-item santamaria-info-item">
														<img src={getIcons("Email", operator)} alt="" />
														<span className="info-text santamaria-info-text">
															<p className="normal-text" style={{ margin: 0 }}>
																Email: {operator.email}
															</p>
														</span>
													</div>
												) : null}
											</React.Fragment>
										) : (
											<div
												className="info-item"
												style={{
													alignItems: "flex-start",
												}}
											>
												<img src={getIcons("Headphone", operator)} alt="" />
												<span
													className="info-text"
													style={{
														color:
															operator &&
																operator.color_codes &&
																operator.color_codes.tertiary_text_color
																? operator.color_codes.tertiary_text_color
																: "",
													}}
												>
													<p className="bold-text" style={{ margin: 0 }}>
														Atención ventas en línea:
													</p>
													<p className="normal-text" style={{ margin: 0 }}>
														{operator && operator.email}
													</p>
													<p className="normal-text" style={{ margin: 0 }}>
														{operator && operator.phone_number}
													</p>
												</span>
											</div>
										)
									}

								</div> */}

                                <div>
                                    {
                                        operator && operator.operator_name === "santamaria" ? (
                                            <React.Fragment>
                                                {operator.phone_number ? (
                                                    <div className="info-item santamaria-info-item">
                                                        <img src={getIcons("Headphone", operator)} alt="" />
                                                        <span className="info-text santamaria-info-text">
                                                            <p className="normal-text" style={{ margin: 0 }}>
                                                                Teléfono: {operator.phone_number}
                                                            </p>
                                                        </span>
                                                    </div>
                                                ) : null}
                                                {operator.email ? (
                                                    <div className="info-item santamaria-info-item">
                                                        <img src={getIcons("Email", operator)} alt="" />
                                                        <span className="info-text santamaria-info-text">
                                                            <p className="normal-text" style={{ margin: 0 }}>
                                                                Email: {operator.email}
                                                            </p>
                                                        </span>
                                                    </div>
                                                ) : null}
                                            </React.Fragment>
                                        ) : (
                                            operator.operator_name !== "biaggini" && (
                                                <div
                                                    className="info-item"
                                                    style={{
                                                        alignItems: "flex-start",
                                                    }}
                                                >
                                                    <img src={getIcons("Headphone", operator)} alt="" />
                                                    <span
                                                        className="info-text"
                                                        style={{
                                                            color:
                                                                operator &&
                                                                    operator.color_codes &&
                                                                    operator.color_codes.tertiary_text_color
                                                                    ? operator.color_codes.tertiary_text_color
                                                                    : "",
                                                        }}
                                                    >
                                                        {operator && operator.operator_name !== "cormarbus" && (
                                                            <p className="bold-text" style={{ margin: 0 }}>
                                                                Atención ventas en línea:
                                                            </p>
                                                        )}

                                                        <p className="normal-text" style={{ margin: 0 }}>
                                                            {operator && operator.email}
                                                        </p>
                                                        <p className="normal-text" style={{ margin: 0 }}>
                                                            {operator && operator.phone_number}
                                                        </p>
                                                    </span>
                                                </div>
                                            )
                                        )
                                    }
                                </div>
                            </React.Fragment>
                        )}
                    </div>


                    {/* <div className="contact">
    <div className="contact-header">
        <div
            className="contact-header-text font13"
            style={{
                color: operator &&
                       operator.color_codes &&
                       operator.color_codes.primary_text_color
                    ? operator.color_codes.primary_text_color
                    : "",
                borderBottom: operator &&
                              operator.color_codes &&
                              operator.color_codes.button_color
                    ? `2px solid ${operator.color_codes.button_color}`
                    : "",
            }}
        >
            Contáctanos
        </div>
    </div>
    <div
        className="contact-bottom"
        style={{
            backgroundColor: operator && operator.operator_name === "biaggini"
                ? "#464647"
                : operator && operator.operator_name === "serenamar"
                ? operator && operator.color_codes && operator.color_codes.primary_text_color
                    ? operator.color_codes.primary_text_color
                    : ""
                : operator && operator.color_codes && operator.color_codes.secondary_color
                ? operator.color_codes.secondary_color
                : "",
        }}
    >
        <div className="align-contact-inputs">
            <div className="contact-input-item">
                <style>
                    {`
                    .ThemeTwoContactForm .contact .contact-bottom .contact-input-label {
                        color: ${operator &&
                                operator.color_codes &&
                                operator.color_codes.secondary_text_color
                            ? operator.color_codes.secondary_text_color
                            : ""};
                    }
                    `}
                </style>
                <div className="contact-input-label">Nombre</div>
                <PBInput
                    placeholder="Nombre"
                    value={state.name}
                    onChange={(text) => inpChange(text, "name")}
                    error={state.nameError ? true : false}
                    errorMessage={state.nameError ? state.nameErrorMessage : ""}
                    containerStyle={{
                        padding: "0",
                        border: 0,
                    }}
                    inputClass={{
                        padding: "5px",
                    }}
                />
            </div>

            {operator && operator.operator_name === "biaggini" ? (
                <div className="contact-input-container side-by-side">
                    <div className="contact-input-item">
                        <div className="contact-input-label">Teléfono</div>
                        <PBInput
                            placeholder="Teléfono"
                            value={state.phone}
                            onChange={(text) => inpChange(text, "phone")}
                            error={state.phoneError ? true : false}
                            errorMessage={state.phoneError ? state.phoneErrorMessage : ""}
                            containerStyle={{ padding: "0", border: 0 }}
                            inputClass={{ padding: "5px", borderRadius: "15px" }}
                        />
                    </div>
                    <div className="contact-input-item">
                        <div className="contact-input-label">Email</div>
                        <PBInput
                            placeholder="Email"
                            value={state.email}
                            onChange={(text) => inpChange(text, "email")}
                            error={state.emailError ? true : false}
                            errorMessage={state.emailError ? state.emailErrorMessage : ""}
                            containerStyle={{ padding: "0", border: 0 }}
                            inputClass={{ padding: "50px", borderRadius: "15px" }}
                        />
                    </div>
                </div>
            ) : (
                <>
                    <div className="contact-input-item">
                        <div className="contact-input-label">Teléfono</div>
                        <PBInput
                            placeholder="Teléfono"
                            value={state.phone}
                            onChange={(text) => inpChange(text, "phone")}
                            error={state.phoneError ? true : false}
                            errorMessage={state.phoneError ? state.phoneErrorMessage : ""}
                            containerStyle={{ padding: "0", border: 0 }}
                            inputClass={{ padding: "5px" }}
                        />
                    </div>
                    <div className="contact-input-item">
                        <div className="contact-input-label">Email</div>
                        <PBInput
                            placeholder="Email"
                            value={state.email}
                            onChange={(text) => inpChange(text, "email")}
                            error={state.emailError ? true : false}
                            errorMessage={state.emailError ? state.emailErrorMessage : ""}
                            containerStyle={{ padding: "0", border: 0 }}
                            inputClass={{ padding: "5px" }}
                        />
                    </div>
                </>
            )}
        </div>

        <div className="contact-input-item" style={{ marginLeft: -10 }}>
            <div className="contact-input-label">Mensaje</div>
            <textarea
                className="text-area font14"
                rows={6}
                placeholder="Mensaje"
                onChange={(event) => inpChange(event.target.value, "comment")}
            />
            <span className="error" style={{ color: "red" }}>
                {state.commentError ? state.commentErrorMessage : null}
            </span>
        </div>

        <div
            className="contact-button"
            style={{
                marginLeft: -10,
                marginTop: 10,
            }}
        >
            {operator && operator.operator_name === "biaggini" ? (
                <PButton
                    title={"BUSCAR"}
                    onPress={onSubmit}
                    style={{
                        backgroundColor: "#0092c5",
                    }}
                />
            ) : (
                <PButton title={"ENVIAR"} onPress={onSubmit} />
            )}
        </div>
    </div>
</div> */}

                    <div className="contact">
                        <div className="contact-header">
                            <div
                                className="contact-header-text font13"
                                style={{
                                    color: operator &&
                                        operator.color_codes &&
                                        operator.color_codes.primary_text_color
                                        ? operator.color_codes.primary_text_color
                                        : "",
                                    borderBottom: operator &&
                                        operator.color_codes &&
                                        operator.color_codes.button_color
                                        ? `2px solid ${operator.color_codes.button_color}`
                                        : "",
                                }}
                            >
                                Contáctanos
                            </div>
                        </div>
                        <div
                            className="contact-bottom"
                            style={{
                                backgroundColor: operator && operator.operator_name === "biaggini"
                                    ? "#464647"
                                    : operator && operator.operator_name === "serenamar"
                                        ? operator && operator.color_codes && operator.color_codes.primary_text_color
                                            ? operator.color_codes.primary_text_color
                                            : ""
                                        : operator && operator.color_codes && operator.color_codes.secondary_color
                                            ? operator.color_codes.secondary_color
                                            : "",
                            }}
                        >
                            <div className="align-contact-inputs">
                                <div className="contact-input-item">
                                    <style>
                                        {`
                    .ThemeTwoContactForm .contact .contact-bottom .contact-input-label {
                        color: ${operator &&
                                                operator.color_codes &&
                                                operator.color_codes.secondary_text_color
                                                ? operator.color_codes.secondary_text_color
                                                : ""};
                    }
                    `}
                                    </style>
                                    {/* <div className="contact-input-label">Nombre</div>
                                    <PBInput
                                        {operator && operator.operator_name !== "biaggini" ? placeholder="Nombre" : " "}
                                        value={state.name}
                                        onChange={(text) => inpChange(text, "name")}
                                        error={state.nameError ? true : false}
                                        errorMessage={state.nameError ? state.nameErrorMessage : ""}
                                        containerStyle={{
                                            padding: "0",
                                            border: 0,
                                        }}
                                        inputClass={{
                                            padding: "5px",
                                        }}
                                    />
                                </div> */}
                                    {/* <div className="contact-input-label" style={{fontSize:"14px"}}>Nombre</div> */}
                                    <div className="contact-input-label" style={{ fontSize: operator.operator_name === "biaggini" ? "14px" : "inherit" }}>
                                        Nombre
                                    </div>

                                    <PBInput
                                        placeholder={operator && operator.operator_name !== "biaggini" ? "Nombre" : ""}
                                        value={state.name}
                                        onChange={(text) => inpChange(text, "name")}
                                        error={state.nameError ? true : false}
                                        errorMessage={state.nameError ? state.nameErrorMessage : ""}
                                        containerStyle={{
                                            padding: "0",
                                            border: 0,
                                        }}
                                        inputClass={{
                                            padding: "5px",
                                        }}
                                    />
                                </div>

                                {operator && operator.operator_name === "biaggini" ? (
                                    <div className="contact-input-container side-by-side">
                                        <div className="contact-input-item">
                                            <div className="contact-input-label" style={{ fontSize: "14px" }}>Correo</div>
                                            <PBInput
                                                // placeholder="Email"
                                                value={state.email}
                                                onChange={(text) => inpChange(text, "email")}
                                                error={state.emailError ? true : false}
                                                errorMessage={state.emailError ? state.emailErrorMessage : ""}
                                                containerStyle={{ padding: "0", border: 0 }}
                                                inputClass={{ padding: "50px", borderRadius: "15px" }}
                                                style={{ width: "130px" }}
                                            />
                                        </div>
                                        <div className="contact-input-item">
                                            <div className="contact-input-label" style={{ fontSize: "14px" }}>No de contacto</div>
                                            <PBInput
                                                // placeholder="Teléfono"
                                                value={state.phone}
                                                onChange={(text) => inpChange(text, "phone")}
                                                error={state.phoneError ? true : false}
                                                errorMessage={state.phoneError ? state.phoneErrorMessage : ""}
                                                containerStyle={{ padding: "0", border: 0 }}
                                                inputClass={{ padding: "5px", borderRadius: "15px" }}
                                                style={{ width: "129px" }}
                                            />
                                        </div>

                                    </div>
                                ) : (
                                    <>
                                        <div className="contact-input-item">
                                            <div className="contact-input-label">Teléfono</div>
                                            <PBInput
                                                placeholder="Teléfono"
                                                value={state.phone}
                                                onChange={(text) => inpChange(text, "phone")}
                                                error={state.phoneError ? true : false}
                                                errorMessage={state.phoneError ? state.phoneErrorMessage : ""}
                                                containerStyle={{ padding: "0", border: 0 }}
                                                inputClass={{ padding: "5px" }}
                                            />
                                        </div>
                                        <div className="contact-input-item">
                                            <div className="contact-input-label">Email</div>
                                            <PBInput
                                                placeholder="Email"
                                                value={state.email}
                                                onChange={(text) => inpChange(text, "email")}
                                                error={state.emailError ? true : false}
                                                errorMessage={state.emailError ? state.emailErrorMessage : ""}
                                                containerStyle={{ padding: "0", border: 0 }}
                                                inputClass={{ padding: "5px" }}
                                            />
                                        </div>
                                    </>
                                )}
                            </div>

                            {operator && operator.operator_name === "biaggini" ? (
                                <div className="contact-input-item" style={{ marginLeft: -7 }}>
                                    <div className="contact-input-label" style={{ fontSize: "14px" }}>Mensaje</div>
                                    <input
                                        className="text-area font14"
                                        onChange={(event) => inpChange(event.target.value, "comment")}
                                        containerStyle={{ padding: "0", border: 0 }}
                                        style={{ width: "273px",height:"40px", }}
                                    />
                                    <span className="error" style={{ color: "red" }}>
                                        {state.commentError ? state.commentErrorMessage : null}
                                    </span>
                                </div>
                            ) : (
                                <div className="contact-input-item" style={{ marginLeft: -10 }}>
                                    <div className="contact-input-label">Mensaje</div>
                                    <textarea
                                        className="text-area font14"
                                        rows={6}
                                        placeholder="Mensaje"
                                        onChange={(event) => inpChange(event.target.value, "comment")}
                                    />
                                    <span className="error" style={{ color: "red" }}>
                                        {state.commentError ? state.commentErrorMessage : null}
                                    </span>
                                </div>
                            )}

                            <div
                                className="contact-button"
                                style={{
                                    marginLeft: -10,
                                    marginTop: 10,
                                }}
                            >
                                {operator && operator.operator_name === "biaggini" ? (
                                    <PButton
                                        title={"ENVIAR"}
                                        onPress={onSubmit}
                                        style={{
                                            backgroundColor: "#0092c5",
                                            marginTop: "20px"
                                        }}
                                    />
                                ) : (
                                    <PButton title={"ENVIAR"} onPress={onSubmit} />
                                )}
                            </div>
                        </div>
                    </div>


                    {operator.operator_name === "biaggini" && renderBiagginiInfo()}
                </div>
                <CarpoolSuccessFailureModal
                    showModal={state.showModal}
                    success={state.status ? true : false}
                    smallIcon={true}
                    bodyText={state.responseMessage}
                    buttonText={"OK"}
                    onButtonPress={() => {
                        setState({
                            showModal: false,
                        });
                        props.history.push("/");
                    }}
                />
            </div>
        )
    );
};

export default connectData()(ThemeTwoContactForm);