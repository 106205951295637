import { AboutUsIconSection } from "../../common/about-us/AboutUsIconSection";
import HeroSection from "../../common/HeroSection/HeroSection";
import React from "react";
import { IonContent, IonPage } from "@ionic/react";
import { connectData } from "../../../redux";
import Header from "../../common/header/Header";
import ContactForm1 from "../../common/contact/ContactForm1";
import Footer2 from "../../common/footer/Footer2";
import getIcons from "../../functions/getIcons/getIcons";

const getContactIcons = (operator) => {
  if (operator) {
    return [
      {
        icon: getIcons("contactHeadphone"),
        caption: operator.phone_number ? operator.phone_number : "N/A",
        // borderRight: 'borderRight-about-icons',
      },
      {
        icon: getIcons("contactMail"),
        caption: operator.address ? operator.address : "N/A",
      },
    ];
  } else return [];
};

const ThemeFiveContactUsScreen = (props) => {
  const operator =
    JSON.parse(localStorage.getItem("op")) || props.data.operator;
  console.log("props in themefivecon", props);
  return (
    <IonPage>
      <IonContent>
        <Header />
        <HeroSection
          height={"30vh"}
          heroImage={getIcons("contactUsBanner")}
          title={"Contacto"}
          description={"Home / Contacto"}
        />
        <div className="mt10">
          <AboutUsIconSection
            smallHeading={"Comunicate"}
            boldHeading={"CON NOSOTROS"}
            iconsAndTexts={getContactIcons(operator)}
          />
        </div>
        {operator && operator.operator_name === "centraltour" ? (
          <div style={{ marginTop: "100px" }} />
        ) : (
          <ContactForm1 history={props.history} />
        )}
        <Footer2 operator={operator} />
      </IonContent>
    </IonPage>
  );
};

export default connectData()(ThemeFiveContactUsScreen);
