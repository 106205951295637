import { IonContent, IonPage } from '@ionic/react';
import React from 'react';
import { connectData } from '../../../redux';
import Header from '../../common/header/Header';
import ThemeSevenFooter from '../common/ThemeSevenFooter';
import './ThemeSevenAboutUsScreen.css';

const ThemeSevenAboutUsScreen = (props) => {
  return (
    <IonPage>
      <IonContent>
        <Header />
        <div className='themes-theme-seven-about-us-screen'>
          <div id='about-us'>
            <div className='new-container'>
              <div className='heading'>
                <h1>Acerca de nosotros</h1>
              </div>
            </div>
          </div>

          <div className='new-container'>
            <div className='col-md-12'>
              <div className='about-us'>
                <div className=''>
                  <div className='about'>
                    <h3>
                      <span>¿Quiénes somos?</span>
                    </h3>
                    <p>
                      Buses Fernández, creada en 1947, es la empresa de
                      transporte de pasajeros más antigua de la Región. El
                      servicio más representativo lo constituye el traslado de
                      pasajeros, carga y correspondencia entre las ciudades de
                      Punta Arenas y Puerto Natales.
                    </p>
                    <p>
                      La empresa cuenta actualmente con 40 modernos buses, un
                      terminal propio de pasajeros en Punta Arenas con todas sus
                      comodidades y terminales de carga en ambas ciudades.
                    </p>
                    <p>
                      También se destacan en la temporada veraniega los viajes
                      de turismo al Parque Nacional Torres del Paine, la
                      atención de los diversos buques de turismo que arriban a
                      la región y el traslado a diversos lugares de la zona de
                      delegaciones estudiantiles, culturales y deportivas.
                    </p>
                    <p>
                      Otra de las labores importantes es el transporte de
                      trabajadores de diversas empresas desde la ciudad a los
                      sitios donde desempeñan sus funciones.
                    </p>
                  </div>
                  <div className='vision'>
                    <h4>
                      <span>Visión</span>
                    </h4>
                    <p>
                      Ser líderes y reconocidos en el mercado como una empresa
                      de transporte de pasajeros y carga de excelencia a nivel
                      regional.
                    </p>
                  </div>
                </div>
                <div className=''>
                  <div className='mision'>
                    <h4>
                      <span>Misión</span>
                    </h4>
                    <p>
                      Somos una empresa familiar de transporte terrestre de
                      pasajeros, orientada a brindar un servicio con seguridad,
                      comodidad, puntualidad y buen trato, satisfaciendo las
                      necesidades de nuestros clientes, buscando el mejoramiento
                      continuo con el propósito de lograr la excelencia del
                      servicio y la fidelidad de nuestros clientes.
                    </p>
                  </div>
                  <div className='history'>
                    <h4>
                      <span>Historia</span>
                    </h4>
                    <p>
                      Fundada el 7 de Octubre de 1947 por Don Secundino
                      Fernández Malebran, padre del actual propietario don
                      Secundino Fernandez Gomez.
                    </p>
                    <p>
                      Como primer viaje desde Punta Arenas a Puerto Natales solo
                      viajaron 4 pasajeros, en una góndola año 1944 marca
                      diamond, con una carrocería importada argentina, de 16
                      asientos de mimbre, con cojines y sin calefacción.
                    </p>
                    <p>
                      Los caminos, eran de muy malas condiciones, en esa época
                      no existían motoniveladoras ni máquinas para arreglar el
                      camino, sólo se construía a palas y picota, unido a las
                      inclemencias del clima ... Como resultado, los viajes
                      extensos, con muchas paradas en el camino, duraban aprox
                      entre 12 y 14 horas.
                    </p>
                  </div>
                </div>
                <div className='clearfix'></div>
              </div>
            </div>
          </div>

          {/* ------------ */}

          <div className='new-container'>
            <div className='board'>
              <h3>
                <span>Nuestro equipo</span>
              </h3>
              <div className='flex-row jc'>
                <div className='game-board'>
                  <div className='box'>
                    <div className='text-center'>
                      <img
                        src='/assets/fernandez/about-us/BusesFernandez-Website-AboutUs-Team-SecundinoFernandez.png'
                        alt='board1'
                      />
                      <div className='name'>Secundino Fernández Gómez</div>
                      <div className='post'>Gerente - Director</div>
                    </div>
                  </div>
                  <div className='box'>
                    <div className='text-center'>
                      <img
                        src='/assets/fernandez/about-us/BusesFernandez-Website-AboutUs-Team-KarinaFernandez.png'
                        alt='board2'
                      />
                      <div className='name'>Karina Fernandez Wainnrigth</div>
                      <div className='post'>Gerente Comercial y Marketing</div>
                    </div>
                  </div>
                  <div className='box'>
                    <div className='text-center'>
                      <img
                        src='/assets/fernandez/about-us/BusesFernandez-Website-AboutUs-Team-PaulinaNunez.png'
                        alt='board3'
                      />
                      <div className='name'>Paulina Núñez Ortiz</div>
                      <div className='post'>Sub Gerente Operaciones</div>
                    </div>
                  </div>
                  <div className='box'>
                    <div className='text-center'>
                      <img
                        src='/assets/fernandez/about-us/BusesFernandez-Website-AboutUs-Team-GastonGarrone.png'
                        alt='board4'
                      />
                      <div className='name'>Gastón Garrone Harambour</div>
                      <div className='post'>Jefe Administrativo Taller</div>
                    </div>
                  </div>
                  <div className='box'>
                    <div className='text-center'>
                      <img
                        src='/assets/fernandez/about-us/BusesFernandez-Website-AboutUs-Team-TeresaMunoz.png'
                        alt='board5'
                      />
                      <div className='name'>Teresa Muñoz</div>
                      <div className='post'>Jefe Administrativo</div>
                    </div>
                  </div>
                  <div className='box'>
                    <div className='text-center'>
                      <img
                        src='/assets/fernandez/about-us/BusesFernandez-Website-AboutUs-Team-RigobertoOjeda.png'
                        alt='board6'
                      />
                      <div className='name'>Rigoberto Ojeda</div>
                      <div className='post'>Jefe Mecánico Taller</div>
                    </div>
                  </div>
                  <div className='box'>
                    <div className='text-center'>
                      <img
                        src='/assets/fernandez/about-us/BusesFernandez-Website-AboutUs-Team-RoxanaMunoz.png'
                        alt='board7'
                      />
                      <div className='name'>Roxana Muñoz Castro</div>
                      <div className='post'>
                        Supervisora Terminal Punta Arenas
                      </div>
                    </div>
                  </div>
                  <div className='box'>
                    <div className='text-center'>
                      <img
                        src='/assets/fernandez/about-us/BusesFernandez-Website-AboutUs-Team-VictoriaBaez.png'
                        alt='board8'
                      />
                      <div className='name'>Victoria Baez</div>
                      <div className='post'>
                        Supervisora Terminal Puerto Natales
                      </div>
                    </div>
                  </div>
                  <div className='box'>
                    <div className='text-center'>
                      <img
                        src='/assets/fernandez/about-us/BusesFernandez-Website-AboutUs-Team-JoseAlvarez.png'
                        alt='board9'
                      />
                      <div className='name'>Jose Álvarez</div>
                      <div className='post'>
                        Supervisor de carga Puerto Natales
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ThemeSevenFooter />
      </IonContent>
    </IonPage>
  );
};

export default connectData()(ThemeSevenAboutUsScreen);
