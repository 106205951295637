import { IonContent, IonPage } from '@ionic/react';

import { AboutUs1 } from '../../common/about-us/AboutUs1';
import { AboutUsIconSection } from '../../common/about-us/AboutUsIconSection';
import Footer2 from '../../common/footer/Footer2';
import Header from '../../common/header/Header';
import HeroSection from '../../common/HeroSection/HeroSection';
import React from 'react';
import { connectData } from '../../../redux';
import getIcons from '../../functions/getIcons/getIcons';

const teupalIcon1 = '/assets/tepual/icons/camera-about-us.svg';
const teupalIcon2 = '/assets/tepual/icons/urban-about-us.svg';
const teupalIcon3 = '/assets/tepual/icons/location-about-us.svg';

const expresonorteServiceIcon1 =
	'/assets/expressonorte/general/serviceIcon1.svg';
const expresonorteServiceIcon2 =
	'/assets/expressonorte/general/serviceIcon2.svg';
const expresonorteServiceIcon3 =
	'/assets/expressonorte/general/serviceIcon3.svg';
const expresonorteServiceIcon4 =
	'/assets/expressonorte/general/serviceIcon4.svg';
const expresonorteServiceIcon5 =
	'/assets/expressonorte/general/serviceIcon5.svg';

const teupalIconsAndTexts = [
	{ icon: teupalIcon1, caption: 'Giras de estudio' },
	{ icon: teupalIcon2, caption: 'Transporte interurbano' },
	{ icon: teupalIcon3, caption: 'Viajes especiales' },
];

const expressonorteIconsAndTexts = [
	{ icon: expresonorteServiceIcon1, caption: 'Cargador USB' },
	{ icon: expresonorteServiceIcon2, caption: 'Semi cama' },
	{ icon: expresonorteServiceIcon3, caption: 'Salón cama' },
	{ icon: expresonorteServiceIcon4, caption: 'Snacks' },
	{ icon: expresonorteServiceIcon5, caption: 'Entretención' },
];

const getIconsAndTexts = operator => {
	const name = operator && operator.operator_name;
	if (name === 'tepual') {
		return teupalIconsAndTexts;
	} else if (name === 'expressonorte') {
		return expressonorteIconsAndTexts;
	} else {
		return [];
	}
};

const ThemeFiveAboutUsScreen = props => {
	const operator =
		JSON.parse(localStorage.getItem('op')) || props.data.operator;

	return (
		<IonPage>
			<IonContent>
				<Header />

				<HeroSection
					title={'Nosotros'}
					height={'30vh'}
					description={'Home / Nosotros'}
					heroImage={getIcons('aboutUsBanner')}
				/>

				<div className="new-container">
					<AboutUs1
						aboutUsBody={
							operator && operator.about_us
								? operator.about_us
								: ''
						}
						imageSource={getIcons('aboutUsImage')}
					/>

					{/* <AboutUsIconSection
						smallHeading={'Conoce'}
						boldHeading={'NUESTROS SERVICIOS'}
						iconsAndTexts={getIconsAndTexts(operator)}
					/> */}

					{/* <OfficesList officeLocations={officeLocations} image={doubleBusImage} operator={operator} /> */}
				</div>
				<Footer2 operator={operator} />
			</IonContent>
		</IonPage>
	);
};

export default connectData()(ThemeFiveAboutUsScreen);
