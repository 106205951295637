import { IonContent, IonPage } from '@ionic/react';
import React from 'react';
import { connectData } from '../../../redux';
import { CommonService } from '../../../services';
import Header from '../../common/header/Header';
import HeroSection from '../../common/HeroSection/HeroSection';
import getIcons from '../../functions/getIcons/getIcons';
import ThemeThreeContactForm from '../common/ThemeThreeContactForm';
import ThemeThreeFooter from '../common/ThemeThreeFooter';
import ThemeThreeTitle from '../common/ThemeThreeTitle';

const ThemeThreeServiceScreen = (props) => {
  const operator = CommonService.getLocal('op') || props.data.operator;
  return (
    <IonPage>
      <IonContent>
        <Header />

        <HeroSection
          heroImage={getIcons('serviceBanner', operator)}
          title='Servicios'
          description='Home / Servicios'
          height={'45vh'}
        />

        <ThemeThreeTitle
          operator={operator}
          title='Conoce nuestros servicios'
        />

        <ThemeThreeContactForm service />
        <ThemeThreeFooter />
      </IonContent>
    </IonPage>
  );
};

export default connectData()(ThemeThreeServiceScreen);
