import React from 'react';
import Header from '../common/header/Header';
import HeroSection from '../common/HeroSection/HeroSection';
import getIcons from '../functions/getIcons/getIcons';
import ThemeTwoServices from './common/ThemeTwoServices';
import KuposHeader from '../../components/KuposHeader';
import { IonContent, IonPage } from '@ionic/react';
import { connectData } from '../../redux';


const ThemeThreeServiceScreen = ({props}) => {
  // const operator = CommonService.getLocal('op') || props.data.operator;
  return (
    <IonPage>
      <IonContent>
								<KuposHeader
            title={"Nuestros"}
            boldTitle={"servicios"}
            handleHomeSubmit={() => props.history.push("/")}
            handleBackSubmit={() => props.history.goBack()}
          />
        <div className="new-container">
									<ThemeTwoServices/>  
									</div>     

      </IonContent>
    </IonPage>
  );
};

export default connectData()(ThemeThreeServiceScreen);
