// @flow
import ReducerUtilService from "../../services/reducerUtilService";
import {
  GET_FACEBOOK_DATA_SUCCESS,
  GET_META_DATA_SUCCESS,
  SHOW_LOADER,
  HIDE_LOADER,
  OPEN_MENU,
  CLOSE_MENU,
  GET_CITIES,
  GET_CITIES_SUCCESS,
  SET_CITIES,
  RETURN_DATE_CHANGE,
  ONWARD_DATE_CHANGE,
  SELECT_CITY_LIST_TYPE,
  SELECT_ORIGIN,
  SELECT_DESTINATION,
  GET_SERVICES_SUCCESS,
  SHOW_HIDE_BUS_LOADER,
  GET_SERVICE_DETAILS_SUCCESS,
  SERVICE_SELECTED_ONWARD,
  SERVICE_SELECTED_RETURN,
  SELECT_SEAT_ONWARD,
  SELECT_SEAT_RETURN,
  SET_PASSENGER_DETAILS_ONWARD,
  SET_PASSENGER_DETAILS_RETURN,
  SET_CONTACT_DETAILS,
  SET_BOOKING_JSON_DETAILS,
  SET_BOOKING_API_RESPONSE,
  LOGIN_SUCCESS,
  SET_CUSTOMER_PROFILE,
  SET_COORDS,
  SET_MARKERS,
  SET_TICKET_DETAILS,
  SET_MY_TICKETS,
  SET_AT_TICKETS,
  SET_TRAIN_TICKETS,
  SET_KUPOS_GO_TICKETS,
  SET_TXN_HISTORY,
  SET_RECENT_CITIES,
  SET_RECENT_CITIES_TRAIN,
  GET_SERVICES_RETURN_SUCCESS,
  SET_MARKER_MODAL,
  SET_CITIES_FROM_HOME,
  SET_CITY_PAIRS_HOME,
  ORIGIN_SEARCH_SELECTED,
  DESTINATION_SEARCH_SELECTED,
  SET_CITIES_UNIQUE,
  SET_DEVICE_INFO,
  SCAN_QR,
  SET_QR_IMAGE,
  SHOW_QR_BG,
  SET_VIRTUAL_MONEY,

  //carpool
  SET_USER_MODE,
  CARPOOL_SET_PICKUP_LOCATION,
  CARPOOL_SET_DROP_LOCATION,
  CARPOOL_DATE_CHANGE,
  CARPOOL_SET_SEARCH_TRIPS,

  //carpool driver
  CARPOOL_DRIVER_DATE_CHANGE,
  CARPOOL_DRIVER_TIME_CHANGE,
  CARPOOL_DRIVER_SET_DROP_LOCATION,
  CARPOOL_DRIVER_SET_PICKUP_LOCATION,
  CARPOOL_DRIVER_SHOW_HIDE_TRIP_ALERT_MODEL,
  CARPOOL_DRIVER_SET_CREATED_TRIP,
  CARPOOL_DRIVER_SET_TRIPS,
  CARPOOL_DRIVER_SET_INFO,
  CARPOOL_SELECT_ONGOING_TRIP,
  CARPOOL_TIME_CHANGE,
  CARPOOL_SET_TRIPS,
  CARPOOL_SET_ORDER_DETAILS,
  CARPOOL_DRIVER_SET_TRIP_DETAILS,
  CARPOOL_DRIVER_SET_TRIP_ORDERS,
  CARPOOL_DRIVER_SET_UPCOMING_HOME_TRIPS,
  SET_LOCAL_DATA,
  SHOW_HIDE_CONTACT_MODAL,
  AIRPORT_TRANSFER_SET_DATA,
  AIRPORT_TRANSFER_SET_ROUTES,
  SET_THIRD_PARTY_APP,
  AIRPORT_TRANSFER_SELECT_SERVICE_TO_BOOK,
  AIRPORT_TRANSFER_RETURN_SERVICE_TO_BOOK,
  KUPOS_GO_SET_DATA,
  REGISTER_SET_USER_PHONE_DATA_FOR_OTP,
  GET_ENVIRONMENT_THINGS,
  SET_CITIES_UNIQUE_TRAIN,
  SET_BIP_CARD_TO_EDIT,
  SAVE_BIP_DETAILS,
  SET_ADD_TO_HOME_SCREEN,
  AIRPORT_TRANSFER_SET_RETURN_ROUTES,
  SAVE_TICKETS_TO_CANCEL,
  SET_AT_TERMINALS,
  SET_SEAT_TYPES,
  SET_AT_BLOCK_TIME,
  SET_INSTANT_DISCOUNT,
  SET_OPERATOR_SITE,
  SET_IS_MOT,
  SET_MOT_VALIDATION,
} from "./actions";
import DateService from "../../../services/dateService";

export const DEFAULT = {
  loginData: null,
  customerData: null,
  isLoading: false,
  isMenuOpen: false,
  metaData: null,
  operatorSites: null,
  cities: null,
  citiesUnique: null,
  citiesUniqueTrain: null,
  citiesData: {
    origins: null,
    destinations: null,
    destinationsList: null,
  },
  originSearchSelected: {
    searchKey: "",
    searchData: null,
  },
  destinationSearchSelected: {
    searchKey: "",
    searchData: null,
  },
  selectedOrigin: null,
  selectedDestination: null,
  selectedOnwardDate: null,
  selectedReturnDate: null,
  selectedCityListType: null,
  recentCities: null,
  showBusLoader: false,
  serviceList: null,
  serviceListReturn: null,
  serviceDetails: null,
  selectedServiceOnward: {
    coachLayoutJson: null,
    originStageDetails: null,
    destinationStageDetails: null,
    boardingStage: null,
    droppingStage: null,
    route: null,
  },
  selectedServiceReturn: {
    coachLayoutJson: null,
    originStageDetails: null,
    destinationStageDetails: null,
    boardingStage: null,
    droppingStage: null,
    route: null,
  },
  bookingDetailsOnward: {
    totalBookingAmount: 0,
    netBookingAmount: 0,
    discountAmount: 0,
    selectedSeatsToBook: null,
    selectedSeatsNumbers: null,
    selectedSeatTypes: null,
  },
  bookingDetailsReturn: {
    totalBookingAmount: 0,
    netBookingAmount: 0,
    discountAmount: 0,
    selectedSeatsToBook: null,
    selectedSeatsNumbers: null,
    selectedSeatTypes: null,
  },
  passengerDetailsOnward: [
    {
      seatNumber: null,
      name: null,
      nationality: null,
      idCardType: null,
      idCardNumber: null,
    },
  ],
  passengerDetailsReturn: [],
  contactDetails: {
    mobile: null,
    email: null,
    confirmEmail: null,
  },
  bookingJSONDetails: null,
  bookingApiResponse: null,
  coords: {
    latitude: null,
    longitude: null,
  },
  markers: [],
  ticketDetails: null,
  myTickets: [],
  TxnHistory: [],
  virtualMoney: [],
  markerModal: null,
  deviceInfo: null,
  scanQR: true,
  qrImage: null,
  showQRBG: false,
  showContactModal: false,
  thirdPartyApp: null,

  //carpools ---
  userMode: "CUSTOMER",
  carpoolPickupLocation: null,
  carpoolDropLocation: null,
  carpoolSelectedDate: null,
  carpoolSelectedTime: null,
  carpoolSearchTrips: null,
  carpoolSelectedOngoingTrip: null,
  carpoolTripsList: null,
  carpoolOrderDetails: null,

  //carpool drivers ---
  carpoolDriverSetInfo: null,
  carpoolDriverPickupLocation: null,
  carpoolDriverDropLocation: null,
  carpoolDriverSelectedDate: null,
  carpoolDriverSelectedTime: null,
  carpoolDriverShowTripAlertModal: false,
  carpoolDriverCreatedTrip: null,
  carpoolDriverTripsList: null,
  carpoolDriverUpcomingHomeTrips: null,
  carpoolDriverSelectedTripDetails: null,
  carpoolDriverSelectedTripOrders: null,

  transferData: {
    selectedService: 0,
    selectedOrigin: null,
    selectedDate: DateService.getTodayString(),
    selectedTime: "00:00",
    selectedKupos: 1,
  },
  kuposGoData: {
    selectedServiceType: null,
    selectedVehicleType: null,
    selectedOrigin: null,
    selectedDestination: null,
    selectedDate: DateService.getTodayString(),
    selectedTime: "00:00",
    selectedKupos: 1,
    viaPoints: [],
    name: null,
    lastName: null,
    email: null,
    mobile: null,
    selectedServiceTypeReturn: null,
    selectedVehicleTypeReturn: null,
    selectedOriginReturn: null,
    selectedDestinationReturn: null,
    selectedDateReturn: DateService.getTodayString(),
    selectedTimeReturn: "00:00",
    selectedKuposReturn: 1,
    viaPointsReturn: [],
  },
  airportTransferRoutes: null,
  airportTransferSelectedServiceToBook: null,
  airportTransferReturnServiceToBook: null,
  bipCardToEdit: null,
  showAddToHomeScreen: false,
  operator: ''
};

export default function data(state = DEFAULT, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case LOGIN_SUCCESS: {
      return {
        ...state,
        loginData: payload,
      };
    }
    case SET_CUSTOMER_PROFILE: {
      return {
        ...state,
        customerData: payload,
      };
    }
    case REGISTER_SET_USER_PHONE_DATA_FOR_OTP: {
      return {
        ...state,
        registerUserPhoneDataForOtp: payload,
      };
    }
    case OPEN_MENU: {
      return {
        ...state,
        isMenuOpen: true,
      };
    }
    case CLOSE_MENU: {
      return {
        ...state,
        isMenuOpen: false,
      };
    }
    case SHOW_LOADER: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case HIDE_LOADER: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case GET_FACEBOOK_DATA_SUCCESS: {
      return {
        ...state,
        user: payload,
      };
    }

    case SET_OPERATOR_SITE: {
      return {
        ...state,
        operator: payload,
      };
    }


    case GET_META_DATA_SUCCESS: {
      return {
        ...state,
        metaData: payload,
        // operatorSites: payload.operator_sites && payload.operator_sites.operator ? payload.operator_sites.operator : ''

        // operatorSites: {
        //   background_color: "rgb(239, 239, 239)",
        //   description: "Compra pasajes de Buses Jetsur en línea, de la manera más fácil y al mejor precio garantizado. Viaja a Temuco, Valdivia, Pucón, Villarrica y más.",
        //   domain_url: "https://www.jetsur.pasaje.cl",
        //   favicon: "https://pb-pool.s3.amazonaws.com/RackMultipart20211103-19228-o4rwi3.png",
        //   footer_logo: "https://pb-pool.s3.amazonaws.com/RackMultipart20211103-10831-e4gtx0.png",
        //   footer_logo_width: "150px",
        //   header_logo: "https://pb-pool.s3.amazonaws.com/RackMultipart20211103-10831-ui0hd6.png",
        //   header_logo_width: "150px",
        //   keywords: "jetsur, buses jetsur, pasajes jetsur, pasajes de bus temuco, pasajes de bus valdivia, pasajes de bus pucon, pasajes bus",
        //   name: "Buses Fierro",
        //   operator_id: 140,
        //   referral_code: "PBC56AJWEW",
        //   title: "Buses Jetsur | Sitio Oficial | Venta de pasajes en línea",
        //   button_color: "rgb(236, 28, 36)",
        //   footer_type: 2,
        //   header_links: '',
        //   show_bottom_footer: true,
        //   show_header_links: true,
        //   show_top_header: true,
        //   text_color: "rgb(255, 255, 255)",
        //   theme_color: "rgb(22, 29, 79)",
        //   theme_color2: "rgb(46, 49, 145)",
        //   destination_icon: "https://pb-pool.s3.amazonaws.com/RackMultipart20211130-31835-h9mtu7.png",
        //   origin_icon: "https://pb-pool.s3.amazonaws.com/RackMultipart20211130-31835-jwotn4.png",
        //   radio_check_icons: "https://pb-pool.s3.amazonaws.com/RackMultipart20211201-31455-3hs3j7.png"

        // }
      };
    }
    case GET_CITIES_SUCCESS: {
      return {
        ...state,
        cities: payload,
      };
    }
    case SET_CITIES: {
      return {
        ...state,
        citiesData: ReducerUtilService.setCities(state, payload),
      };
    }
    case SET_CITIES_UNIQUE: {
      return {
        ...state,
        citiesUnique: payload,
      };
    }
    case SET_CITIES_UNIQUE_TRAIN: {
      return {
        ...state,
        citiesUniqueTrain: payload,
      };
    }
    case SET_CITIES_FROM_HOME: {
      return {
        ...state,
        citiesData: payload,
      };
    }
    case SET_CITY_PAIRS_HOME: {
      return {
        ...state,
        cities: payload.citiesSuccess,
        citiesData: payload.setCities,
      };
    }
    case SELECT_CITY_LIST_TYPE: {
      return {
        ...state,
        selectedCityListType: payload,
      };
    }
    case SET_RECENT_CITIES: {
      return {
        ...state,
        recentCities: payload,
      };
    }
    case SET_RECENT_CITIES_TRAIN: {
      return {
        ...state,
        recentCitiesTrain: payload,
      };
    }
    case ORIGIN_SEARCH_SELECTED: {
      return {
        ...state,
        originSearchSelected: payload,
      };
    }
    case DESTINATION_SEARCH_SELECTED: {
      return {
        ...state,
        destinationSearchSelected: payload,
      };
    }
    case SELECT_ORIGIN: {
      return {
        ...state,
        selectedOrigin: payload,
      };
    }
    case SELECT_DESTINATION: {
      return {
        ...state,
        selectedDestination: payload,
      };
    }
    case ONWARD_DATE_CHANGE: {
      return {
        ...state,
        selectedOnwardDate: payload,
      };
    }
    case RETURN_DATE_CHANGE: {
      return {
        ...state,
        selectedReturnDate: payload,
      };
    }
    case SHOW_HIDE_BUS_LOADER: {
      return {
        ...state,
        showBusLoader: payload,
      };
    }
    case GET_SERVICES_SUCCESS: {
      return {
        ...state,
        serviceList: {
          routes: ReducerUtilService.getFormattedRoutes(payload.routes),
          stages: payload.stages,
        },
      };
    }
    case GET_SERVICES_RETURN_SUCCESS: {
      return {
        ...state,
        serviceListReturn: {
          routes: ReducerUtilService.getFormattedRoutes(payload.routes),
          stages: payload.stages,
        },
      };
    }
    case GET_SERVICE_DETAILS_SUCCESS: {
      return {
        ...state,
        serviceDetails: payload,
      };
    }

    case SERVICE_SELECTED_ONWARD: {
      return {
        ...state,
        selectedServiceOnward: payload,
      };
    }
    case SERVICE_SELECTED_RETURN: {
      return {
        ...state,
        selectedServiceReturn: payload,
      };
    }
    case SELECT_SEAT_ONWARD: {
      return {
        ...state,
        bookingDetailsOnward: payload,
      };
    }
    case SELECT_SEAT_RETURN: {
      return {
        ...state,
        bookingDetailsReturn: payload,
      };
    }
    case SET_PASSENGER_DETAILS_ONWARD: {
      return {
        ...state,
        passengerDetailsOnward: payload,
      };
    }
    case SET_PASSENGER_DETAILS_RETURN: {
      return {
        ...state,
        passengerDetailsReturn: payload,
      };
    }
    case SET_CONTACT_DETAILS: {
      return {
        ...state,
        contactDetails: payload,
      };
    }
    case SET_BOOKING_JSON_DETAILS: {
      return {
        ...state,
        bookingJSONDetails: payload,
      };
    }
    case SET_BOOKING_API_RESPONSE: {
      return {
        ...state,
        bookingApiResponse: payload,
      };
    }
    case SET_COORDS: {
      return {
        ...state,
        coords: payload,
      };
    }
    case SET_MARKERS: {
      return {
        ...state,
        markers: payload,
      };
    }
    case SET_TICKET_DETAILS: {
      return {
        ...state,
        ticketDetails: payload,
      };
    }
    case SET_MY_TICKETS: {
      return {
        ...state,
        myTickets: payload,
      };
    }
    case SET_AT_TICKETS: {
      return {
        ...state,
        atTickets: payload,
      };
    }
    case SET_TRAIN_TICKETS: {
      return {
        ...state,
        trainTickets: payload,
      };
    }

    case SET_KUPOS_GO_TICKETS: {
      return {
        ...state,
        kuposGoTickets: payload,
      };
    }

    case GET_ENVIRONMENT_THINGS: {
      return {
        ...state,
        environmentThings: payload,
      };
    }
    case SET_TXN_HISTORY: {
      return {
        ...state,
        TxnHistory: payload,
      };
    }
    case SET_VIRTUAL_MONEY: {
      return {
        ...state,
        virtualMoney: payload,
      };
    }
    case SET_MARKER_MODAL: {
      return {
        ...state,
        markerModal: payload,
      };
    }
    case SET_DEVICE_INFO: {
      return {
        ...state,
        deviceInfo: payload,
      };
    }
    case SCAN_QR: {
      return {
        ...state,
        scanQR: payload,
      };
    }
    case SHOW_QR_BG: {
      return {
        ...state,
        showQRBG: payload,
      };
    }
    case SET_QR_IMAGE: {
      return {
        ...state,
        qrImage: payload,
      };
    }
    case SET_LOCAL_DATA: {
      return {
        ...state,
        ...payload,
      };
    }
    case SHOW_HIDE_CONTACT_MODAL: {
      return {
        ...state,
        showContactModal: payload,
      };
    }
    case SET_THIRD_PARTY_APP: {
      return {
        ...state,
        thirdPartyApp: payload,
      };
    }

    //carpools
    case SET_USER_MODE: {
      return {
        ...state,
        userMode: payload,
      };
    }
    case CARPOOL_SET_PICKUP_LOCATION: {
      return {
        ...state,
        carpoolPickupLocation: payload,
      };
    }
    case CARPOOL_SET_DROP_LOCATION: {
      return {
        ...state,
        carpoolDropLocation: payload,
      };
    }
    case CARPOOL_DATE_CHANGE: {
      return {
        ...state,
        carpoolSelectedDate: payload,
      };
    }
    case CARPOOL_TIME_CHANGE: {
      return {
        ...state,
        carpoolSelectedTime: payload,
      };
    }
    case CARPOOL_SET_SEARCH_TRIPS: {
      return {
        ...state,
        carpoolSearchTrips: payload,
      };
    }
    case CARPOOL_SELECT_ONGOING_TRIP: {
      return {
        ...state,
        carpoolSelectedOngoingTrip: payload,
      };
    }
    case CARPOOL_SET_TRIPS: {
      return {
        ...state,
        carpoolTripsList: payload,
      };
    }
    case CARPOOL_SET_ORDER_DETAILS: {
      return {
        ...state,
        carpoolOrderDetails: payload,
      };
    }

    //carpools drivers

    case CARPOOL_DRIVER_SET_INFO: {
      return {
        ...state,
        carpoolDriverSetInfo: payload,
      };
    }

    case CARPOOL_DRIVER_SET_PICKUP_LOCATION: {
      return {
        ...state,
        carpoolDriverPickupLocation: payload,
      };
    }

    case CARPOOL_DRIVER_SET_DROP_LOCATION: {
      return {
        ...state,
        carpoolDriverDropLocation: payload,
      };
    }
    case CARPOOL_DRIVER_DATE_CHANGE: {
      return {
        ...state,
        carpoolDriverSelectedDate: payload,
      };
    }
    case CARPOOL_DRIVER_TIME_CHANGE: {
      return {
        ...state,
        carpoolDriverSelectedTime: payload,
      };
    }
    case CARPOOL_DRIVER_SHOW_HIDE_TRIP_ALERT_MODEL: {
      return {
        ...state,
        carpoolDriverShowTripAlertModal: payload,
      };
    }

    case CARPOOL_DRIVER_SET_CREATED_TRIP: {
      return {
        ...state,
        carpoolDriverCreatedTrip: payload,
      };
    }

    case CARPOOL_DRIVER_SET_TRIPS: {
      return {
        ...state,
        carpoolDriverTripsList: payload,
      };
    }

    case CARPOOL_DRIVER_SET_UPCOMING_HOME_TRIPS: {
      return {
        ...state,
        carpoolDriverUpcomingHomeTrips: payload,
      };
    }

    case CARPOOL_DRIVER_SET_TRIP_DETAILS: {
      return {
        ...state,
        carpoolDriverSelectedTripDetails: payload,
      };
    }
    case CARPOOL_DRIVER_SET_TRIP_ORDERS: {
      return {
        ...state,
        carpoolDriverSelectedTripOrders: payload,
      };
    }

    //airport transfer ----------
    case AIRPORT_TRANSFER_SET_DATA: {
      return {
        ...state,
        transferData: { ...state.transferData, ...payload },
      };
    }
    case AIRPORT_TRANSFER_SET_ROUTES: {
      return {
        ...state,
        airportTransferRoutes: payload,
      };
    }
    case AIRPORT_TRANSFER_SET_RETURN_ROUTES: {
      return {
        ...state,
        airportTransferReturnRoutes: payload,
      };
    }

    case AIRPORT_TRANSFER_SELECT_SERVICE_TO_BOOK: {
      return {
        ...state,
        airportTransferSelectedServiceToBook: payload,
      };
    }

    case AIRPORT_TRANSFER_RETURN_SERVICE_TO_BOOK: {
      return {
        ...state,
        airportTransferReturnServiceToBook: payload,
      };
    }

    case KUPOS_GO_SET_DATA: {
      return {
        ...state,
        kuposGoData: { ...state.kuposGoData, ...payload },
      };
    }

    case SET_BIP_CARD_TO_EDIT: {
      return {
        ...state,
        bipCardToEdit: payload,
      };
    }

    case SAVE_BIP_DETAILS: {
      return {
        ...state,
        bipData: {
          ...state.bipData,
          ...payload,
        },
      };
    }

    case SET_ADD_TO_HOME_SCREEN: {
      return {
        ...state,
        showAddToHomeScreen: payload,
      };
    }

    case SAVE_TICKETS_TO_CANCEL: {
      return {
        ...state,
        numberOfATSeatsTocancel: payload,
      };
    }

    case SET_AT_TERMINALS: {
      return {
        ...state,
        atTerminals: payload,
      };
    }
    case SET_SEAT_TYPES: {
      return {
        ...state,
        atMastersData: payload,
      };
    }
    case SET_AT_BLOCK_TIME: {
      return {
        ...state,
        blockTime: payload,
      };
    }

    case SET_INSTANT_DISCOUNT: {
      return {
        ...state,
        instantDiscount: payload,
      };
    }

    case SET_IS_MOT: {
      return {
        ...state,
        isMot: payload,
      };
    }
    case SET_MOT_VALIDATION: {
      return {
        ...state,
        motValidationData: payload,
      };
    }
    default:
      return state;
  }
}
