import './ThemeFiveOfficeSection.css';

import React, { useEffect, useState } from 'react';

import { AppData } from '../../../services';
import GoogleMapReact from 'google-map-react';
import { connectData } from '../../../redux';
import getIcons from '../../functions/getIcons/getIcons';

// const data = [
//   {
//     title: 'santiago',
//     active: true,
//     subOffices: [
//       {
//         terminal: 'santiago',
//         address: 'address',
//         phone: 'phone',
//         lat: '-33.456130',
//         lng: '-70.688390',
//       },
//       {
//         terminal: 'santiago',
//         address: 'address',
//         phone: 'phone',
//         lat: '-33.456130',
//         lng: '-70.688390',
//       },
//       {
//         terminal: 'santiago',
//         address: 'address',
//         phone: 'phone',
//         lat: '-33.456130',
//         lng: '-70.688390',
//       },
//       {
//         terminal: 'santiago',
//         address: 'address',
//         phone: 'phone',
//         lat: '-33.456130',
//         lng: '-70.688390',
//       },
//     ],
//   },

//   {
//     title: 'Sringar',
//     subOffices: [
//       {
//         city: 'Sringar',
//         address: 'address',
//         lat: '3424',
//         lng: '3434',
//       },
//       {
//         city: 'Sringar',
//         address: 'address',
//         lat: '3424',
//         lng: '3434',
//       },
//     ],
//   },

//   {
//     title: 'Ganderbal',
//     subOffices: [
//       {
//         city: 'Sringar',
//         address: 'address',
//         lat: '3424',
//         lng: '3434',
//       },
//       {
//         city: 'Sringar',
//         address: 'address',
//         lat: '3424',
//         lng: '3434',
//       },
//     ],
//   },

//   {
//     title: 'Baramulla',
//     subOffices: [
//       {
//         city: 'Sringar',
//         address: 'address',
//         lat: '3424',
//         lng: '3434',
//       },
//       {
//         city: 'Sringar',
//         address: 'address',
//         lat: '3424',
//         lng: '3434',
//       },
//     ],
//   },

//   {
//     title: 'Budgam',
//     subOffices: [
//       {
//         city: 'Sringar',
//         address: 'address',
//         lat: '3424',
//         lng: '3434',
//       },
//       {
//         city: 'Sringar',
//         address: 'address',
//         lat: '3424',
//         lng: '3434',
//       },
//     ],
//   },

//   {
//     title: 'Ganderxbal',
//     subOffices: [
//       {
//         city: 'Sringar',
//         address: 'address',
//         lat: '3424',
//         lng: '3434',
//       },
//       {
//         city: 'Sringar',
//         address: 'address',
//         lat: '3424',
//         lng: '3434',
//       },
//     ],
//   },

//   {
//     title: 'Baramulkla',
//     subOffices: [
//       {
//         city: 'Sringar',
//         address: 'address',
//         lat: '3424',
//         lng: '3434',
//       },
//       {
//         city: 'Sringar',
//         address: 'address',
//         lat: '3424',
//         lng: '3434',
//       },
//     ],
//   },
// ];

const AnyReactComponent = ({ img }) => (
	<div>
		<img style={{display: 'inline-block',width: '20px',}}	src={img} />
	</div>
);

const ThemeFiveOfficeSection = props => {
	const operator =
		JSON.parse(localStorage.getItem('op')) || props.data.operator;

	const [selectedOffice, setSelectedOffice] = useState({});
	const [offices, setOffices] = useState([]);
	const [latLong, setLatLong] = useState({
		lat: '-33.456130',
		long: '-70.688390',
	});

	useEffect(() => {
		props.getOfficeDetails({ callback: res => onResponse(res) });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// console.log('latLong', latLong);
	const onResponse = res => {
		if (
			res &&
			res.data &&
			res.data.data &&
			res.data.data.offices &&
			res.data.data.offices.length > 0
		) {
			const data = res.data.data.offices.map((val, i) => {
				val.active = i === 0 ? true : false;
				return val;
			});
			setOffices(data);
			setSelectedOffice(data && data[0]);
			setLatLong({
				lat:
					data &&
					data[0].subOffices &&
					data[0].subOffices.length > 0 &&
					data[0].subOffices[0].lat &&
					data[0].subOffices[0].lat,
				long:
					data[0] &&
					data[0].subOffices &&
					data[0].subOffices.length > 0 &&
					data[0].subOffices[0].long &&
					data[0].subOffices[0].long,
			});
		}
	};

	const onSelect = item => {
		const data =
			offices &&
			offices.map((val, i) => {
				val.active = val.title === item.title ? true : false;
				return val;
			});
		setOffices(data);
		setSelectedOffice(item);
		setLatLong({
			lat:
				item &&
				item.subOffices &&
				item.subOffices.length > 0 &&
				item.subOffices[0].lat &&
				item.subOffices[0].lat,
			long:
				item &&
				item.subOffices &&
				item.subOffices.length > 0 &&
				item.subOffices[0].long &&
				item.subOffices[0].long,
		});
	};

	const getDynamicStyling = (item, index) => {
		if (item.active) {
			return {
				color:
					operator &&
					operator.color_codes &&
					operator.color_codes.secondary_color,
				background:
					operator &&
					operator.color_codes &&
					operator.color_codes.selected_off_bg,
			};
		}
		if (index % 2 === 0) {
			return {
				background:
					operator &&
					operator.color_codes &&
					operator.color_codes.primary_off_bg,
			};
		} else {
			return {
				background:
					operator &&
					operator.color_codes &&
					operator.color_codes.secondary_off_bg,
			};
		}
	};

	return (
		<div className="themes-theme-five-office-section">
			<div className="new-container">
				<div className="offices">
					<ul
						className="office__list"
						style={{
							background:
								operator &&
								operator.color_codes &&
								operator.color_codes.selected_off_bg,
						}}
					>
						{offices.map((item, index) => (
							<li
								onClick={() => onSelect(item)}
								// className={`cursor-pointer  office__item ${
								//   index % 2 === 0
								//     ? ' background-light-green '
								//     : ' background-dark-green '
								// }${item.active ? ' selected-location' : ''}`}

								className="cursor-pointer  office__item"
								key={index}
								style={getDynamicStyling(item, index)}
							>
								<span
									className="office__name font11"
									style={
										item.active
											? {
													color: operator.operator_name === 'erbuc' ? '#fff' :
														operator &&
														operator.color_codes &&
														operator
															.color_codes
															.primary_off_bg,
													borderBottom: operator.operator_name === 'erbuc' ? '3px solid #fff' : `3px solid ${
														operator &&
														operator.color_codes &&
														operator.color_codes.primary_off_bg
													}`,
											  }
											: {
													borderBottom: operator.operator_name === 'erbuc' ? '3px solid #fff' : `3px solid ${
														operator &&
														operator.color_codes && operator.color_codes.selected_off_bg
													}`,
													// color:
													// 	operator &&
													// 	operator.color_codes &&
													// 	operator
													// 		.color_codes
													// 		.secondary_text_color,
													color:
														operator.operator_name ===
														'expressonorte'
															? '#000'
															: '#fff',
											  }
									}
								>
									{item.title}
								</span>
							</li>
						))}
					</ul>
					<div className="office__map">
						<div className="office__map-view">
							<GoogleMapReact
								bootstrapURLKeys={{
									key: AppData.MAP_API_KEY,
								}}
								center={{
									lat: parseFloat(latLong.lat),
									lng: parseFloat(latLong.long),
								}}
								defaultZoom={11}
							>
								<AnyReactComponent
									lat={parseFloat(latLong.lat)}
									lng={parseFloat(latLong.long)}
									img="/assets/bahia/General/pin.png"
								/>
							</GoogleMapReact>
						</div>

						<SelectedOffice
							operator={operator}
							selectedOffice={selectedOffice}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

const SelectedOffice = ({ selectedOffice, operator }) => {
	return (
		<div className="office__map-details">
			<div className="office__map-details-text">
				{selectedOffice &&
				selectedOffice.subOffices &&
				selectedOffice.subOffices.length
					? selectedOffice.subOffices.map((value, index) => {
							return (
								<div
									key={index}
									className="selected-office"
								>
									<div
										className="terminal"
										style={{
											borderBottom: `3px solid ${
												operator &&
												operator.color_codes &&
												operator.color_codes
													.primary_color
											}`,
										}}
									>
										{value.terminal}
									</div>
									{value.phone ? (
										<div className="phone">
											<img
												src={getIcons(
													'OfficePhoneIcon'
												)}
												alt=""
												className="icon-size icon-left "
												style={{
													marginRight:
														'9px',
												}}
											/>
											{value.phone}
										</div>
									) : null}
									{value.address ? (
										<div className="address">
											<img
												src={getIcons(
													'Destination'
												)}
												alt=""
												className="icon-size icon-left "
												style={{
													marginRight:
														'4px',
													marginLeft:
														'-4px',
												}}
											/>
											{value.address}
										</div>
									) : null}
								</div>
							);
					  })
					: null}
			</div>
		</div>
	);
};

export default connectData()(ThemeFiveOfficeSection);
