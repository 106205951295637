import { IonContent, IonPage } from '@ionic/react';
import React from 'react';
import { connectData } from '../../../redux';
import Header from '../../common/header/Header';
import ThemeSevenFooter from '../common/ThemeSevenFooter';
import './ThemeSevenPriceAndServicesScreen.css';

const ThemeSevenPriceAndServicesScreen = (props) => {
  return props.data.operator ? (
    <IonPage>
      <IonContent>
        <Header />
        <div className='themes-theme-seven-price-and-services-screen'>
          <div id='prices_services'>
            <div class='new-container'>
              <div class='heading'>
                <h1>Precios y servicios</h1>
              </div>
            </div>
          </div>

          <div className='new-container'>
            <div className='col-md-12'>
              <div className='prices_services'>
                <h3>
                  <span>Precios y servicios</span>
                </h3>
                <div className='price-details'>
                  <img
                    src='/assets/fernandez/services/BusesFernandez-Images-PricesAndServices-Prices-new.png'
                    alt='schedules'
                    width='100%'
                  />
                  <br /> <br /> <br />
                  <img
                    src='/assets/fernandez/services/BusesFernandez-Images-PricesAndServices-Schedules-new-1.png'
                    alt='schedules'
                    width='100%'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <ThemeSevenFooter />
      </IonContent>
    </IonPage>
  ) : null;
};

export default connectData()(ThemeSevenPriceAndServicesScreen);
