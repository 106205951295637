const Banner = '/assets/cormarbus/home/banner.png';
const OriginIcon = '/assets/cormarbus/home/origin.svg';
const DestinationIcon = '/assets/cormarbus/home/destination.svg';
const CalendarIcon = '/assets/cormarbus/home/calendar.svg';
const BookingActive = '/assets/cormarbus/home/ticket-red.svg';
const BookingInActive = '/assets/cormarbus/home/ticket-white.svg';
const CancelActive = '/assets/cormarbus/home/cancel-red.svg';
const CancelInActive = '/assets/cormarbus/home/cancel-white.svg';
const checkboxIcon = '/assets/tepual/checkbox.png';
const HeaderLogo = '/assets/cormarbus/home/cormarbus-logo.svg';
const FooterLogo = '/assets/cormarbus/home/cormarbus-logo-white.png';
const animation = '/images/animations/Services-kupos-PasajeBus-Orange.gif';
const BG = '/assets/cormarbus/home/Cormar-Banner-Mobile.png';
const BottomFooterKuposLogo = '/assets/viatur/Footer-kuposLogo.svg';
const pnr = '/assets/cormarbus/home/find-my-pnr.svg';

// Icons Section
const ist = '/assets/cormarbus/icons/seat.svg';
const second = '/assets/cormarbus/icons/charger.svg';
const third = '/assets/cormarbus/icons/on-board.svg';
const office = '/assets/cormarbus/icons/home.svg';
const HeadPhone = '/assets/cormarbus/icons/sale.svg';
const PopupIcon = '/assets/cormarbus/home/popup.jpg';
const close = '/assets/cormarbus/icons/close.svg';
const MenuDot = '/assets/cormarbus/icons/cormar-sidemenu.svg';



// new
const pay = '/assets/blue/Andipay.png';
const backimage = '/assets/blue/Andimar-BackgroundMobile.png';
const tarjetas = '/assets/blue/IllustratedIcons-MyCards-Blue.png';
const viajes = '/assets/blue/IllustratedIcons-MyTrips-Blue.png';
const movimientos = '/assets/blue/IllustratedIcons-MyMovements-Blue.png';
const wallet = '/assets/blue/MyAccount-IllustratedIcons-RechargeWallet-Blue.png';
const shareMoney = '/assets/blue/MyAccount-IllustratedIcons-ShareMoney-Blue.png';
const camera = '/assets/blue/IllustratedIcons-Camera-Blue.png';
const checkmark = '/assets/blue/IllustratedIcons-CheckMark-Blue.png';
const tick = '/assets/blue/IllustratedIcons-Tick-Blue.png';
const downArrow = '/assets/blue/IllustratedIcons-DropDown-Blue.png';
const loginblue = '/assets/blue/IllustratedIcons-Login-Blue-c.png';
const recoverpswd = '/assets/blue/IllustratedIcons-RecoverPassword-Blue.png';
const success = '/assets/blue/IllustratedIcons-CheckMark-Blue.png';
const failure = '/assets/blue/IllustratedIcons-Information-Blue.png';
const warning = '/assets/blue/IllustratedIcons-Information-Blue.png';
const userIcon = '/assets/blue/IllustratedIcons-user.png';



const heartOn = '/assets/blue/IllustratedIcons-HeartOn-Blue.svg';
const heartOff = '/assets/blue/IllustratedIcons-HeartOff-Blue.svg';
const registrationSuccess = '/assets/blue/IllustratedIcons-RightDocument-Blue.png';

const operatorPay = '/assets/images/cormarpay-logo.jpg'



const cormarbusIcons = [
  { icon: HeaderLogo, name: 'headerLogo' },
  { icon: FooterLogo, name: 'footerLogo' },
  { icon: Banner, name: 'Banner' },
  { icon: OriginIcon, name: 'Origin' },
  { icon: DestinationIcon, name: 'Destination' },
  { icon: CalendarIcon, name: 'Calendar' },
  { icon: BookingInActive, name: 'BIA' },
  { icon: BookingActive, name: 'BA' },
  { icon: CancelInActive, name: 'CIA' },
  { icon: CancelActive, name: 'CA' },
  { icon: animation, name: 'animation' },
  { icon: checkboxIcon, name: 'Checkbox' },
  { icon: office, name: 'office' },
  { icon: HeadPhone, name: 'Headphone' },
  { icon: BG, name: 'BG' },
  { icon: BottomFooterKuposLogo, name: 'BottomFooterKuposLogo' },
  { icon: pnr, name: 'pnr' },

  // Icons Section
  { icon: ist, name: 'ist' },
  { icon: second, name: 'second' },
  { icon: third, name: 'third' },
  { icon: PopupIcon, name: 'Popup' },
  { icon: close, name: 'close' },
  { icon: MenuDot, name: 'menuDot' },

  { icon: pay, name: 'pay' },
  { icon: backimage, name: 'backimage' },
  { icon: tarjetas, name: 'tarjetas' },
  { icon: viajes, name: 'viajes' },
  { icon: movimientos, name: 'movimientos' },
  { icon: wallet, name: 'wallet' },
  { icon: shareMoney, name: 'shareMoney' },
  { icon: camera, name: 'camera' },
  { icon: checkmark, name: 'checkmark' },
  { icon: tick, name: 'tick' },
  { icon: downArrow, name: 'downArrow' },
  { icon: loginblue, name: 'loginblue' },
  { icon: recoverpswd, name: 'recoverpswd' },
  { icon: success, name: 'success' },
  { icon: failure, name: 'failure' },
  { icon: heartOn, name: 'heartOn' },
  { icon: heartOff, name: 'heartOff' },
  { icon: registrationSuccess, name: 'registrationSuccess' },
  { icon: userIcon, name: 'userIcon' },
  { icon: warning, name: 'warning' },
  
  { icon: operatorPay, name: 'operatorPay' },


];

export default cormarbusIcons;
