import React from 'react';
import { connectData } from '../../redux';
import ThemeThreeServiceScreen from '../ThemeThree/ThemeThreePages/ThemeThreeServiceScreen';
import ThemeTwoServicesScreen from '../ThemeTwo/ThemeTwoServicesScreen';

const CommonServicesPage = (props) => {
  const getPage = () => {
    const themeType =
      props.data && props.data.operator && props.data.operator.theme_type;
    if (themeType === 3) {
      return <ThemeThreeServiceScreen />;
    } else if (themeType === 2) {
					return <ThemeTwoServicesScreen props={props}/>
				}
  };
  return <React.Fragment>{getPage()}</React.Fragment>;
};

export default connectData()(CommonServicesPage);
