import React, { PureComponent } from "react";

import { connectData } from "../../redux";
import { commonStyles, colors, dimensions } from "../../theme";
import {
  PBCard,
  PBText,
  PBInput,
  PButton,
  Loader,
  PBModal,
  PBTabs,
  AccountInfoCard,
  PBCarpoolBG,
} from "../../components";
// import { REGISTER_SCREEN, LOGIN_SCREEN,  RECHARGE_MAP_SCREEN, RECHARGE_STEPS_SCREEN } from '../../navigation/Screens';
import { CommonService } from "../../services";
import { IonContent, IonPage } from "@ionic/react";
import { Link } from "react-router-dom";
import getIcons from "../../themes/functions/getIcons/getIcons";

const CustomCallout = (props) => {
  const { marker, location } = props;
  let dialCall = () => {
    let phoneNumber = "";
    phoneNumber = "tel:${" + marker.phone + "}";
    // Linking.openURL(phoneNumber);
  };

  let direction = () => {
    // Linking.openURL('https://www.google.com/maps/dir/' + location.latitude + ',' + location.longitude + '/' + marker.latitude + ',' + marker.longitude)
  };

  return marker ? (
    <div>
      <div style={styles.calloutNameContainer}>
        <PBText primary={true}>{marker.bank.name}</PBText>
      </div>
      <div style={styles.calloutServiceContainer}>
        <PBText>{"Servicios: " + marker.categories.join(", ")}</PBText>
      </div>
      <div style={{ marginTop: 8 }}>
        <PBText>{"Dirección: " + marker.address.toUpperCase()}</PBText>
      </div>
      <div
        style={{
          borderBottomWidth: 1,
          borderBottomColor: "#ccc",
          width: "100%",
        }}
      ></div>
      <div style={styles.calloutCompanyContainer}>
        <PBText>PasajeBus.com</PBText>
      </div>
      <div style={{ marginTop: 8 }}>
        <PBText primary={true}>¿Necesitas ayuda?</PBText>
      </div>
      <div style={styles.calloutCodeContainer}>
        <div onPress={dialCall}>
          <div style={{ width: "100%", flexDirection: "row" }}>
            <img
              width={14}
              style={{ marginRight: 5 }}
              src="/assets/icons/contact-call.png"
            />
            <PBText>{"tel:" + marker.phone}</PBText>
          </div>
        </div>
      </div>
      <div>
        <PButton
          onPress={direction}
          style={{ width: "60%", height: 40, borderRadius: 8 }}
          title="¿CÓMO LLEGAR?"
        ></PButton>
      </div>
    </div>
  ) : null;
};

const DescItem = (props) => {
  return (
    <div className="recharge-desc-container">
      <div className="recharge-desc-text">{props.textLine1}</div>
      <div className="recharge-desc-text">{props.textLine2}</div>
    </div>
  );
};

class RechargeWalletScreen extends PureComponent {
  idCardTypes = [];
  constructor(props) {
    super(props);
    for (let c in this.props.data.metaData.id_card_types) {
      this.idCardTypes.push({
        value: this.props.data.metaData.id_card_types[c],
        id: this.props.data.metaData.id_card_types[c],
        label: c,
        name: c,
        selected:
          this.props.data.metaData.id_card_types[c] ==
          this.props.data.loginData.identity_type,
      });
    }
    this.state = {
      amount: null,
      amountError: null,
      slectedTab: 1,
      showModal: false,
      amountChanged: 1,
    };
    const operator =
      JSON.parse(localStorage.getItem("op")) || this.props.data.operator;
    let query;
    if (CommonService.shouldPassOperatorId(operator)) {
      query = "operator_id=" + CommonService.getOperatorId(operator)
    }
    this.props.getCustomerProfile({
      callback: (result) => this.onProfileLoad(result),
      operator_id: query,
    });
    // CommonService.bindNav(this);
  }

  navigationButtonPressed({ buttonId }) {
    CommonService.rightButonHome(
      buttonId,
      this.props.componentId,
      this.props.returnDateChange
    );
  }
  onProfileLoad = (result) => {
    if (result.statusCode == 200) {
      CommonService.storeLoginDetails(result.data, this.props.loginSuccess);
    } else if (result.statusCode == 401 && result.data.error) {
      this.setState({
        showModal: true,
        modalTitle: result.data.error,
        modalIcon: null,
        modalButtonText: "Iniciar Sesión",
        authFailed: true,
        modalButtonTap: this.goToLogin,
        modalBackButtonPress: this.goToHome,
      });
    } else if (result.data && result.data.error) {
      this.refs.toast.show(result.data.error);
      this.setState({
        showModal: true,
        modalTitle: "¡Lo Sentimos!",
        modalBody: result.data.error,
        modalIcon: "/assets/icons/cross-grey.png",
        modalButtonText: "OK",
        modalButtonTap: this.closeModal,
        modalBackButtonPress: this.closeModal,
      });
    }
  };

  componentDidMount() {
    // Starts the animation
  }

  amountChange = (text) => {
    if (!CommonService.onlyNumericValues(text)) {
      this.setState({
        amountError: {
          message: "El monto solo debe contener dígitos.",
        },
      });
    }

    if (!+text) {
      this.setState({
        amount: null,
        amountError: null,
        amountChanged: this.state.amountChanged + 1,
      });
    } else {
      this.setState({
        amount: CommonService.onlyNumbers(text),
        amountError: null,
      });
    }
  };

  onBlur = (text, inp) => {
    if (!text) {
      if (inp == "amount") {
        this.setState({
          amountError: {
            message: "Ingresa el monto a recargar",
          },
        });
      }
    }
  };

  onSubmit = () => {
    // Keyboard.dismiss();
    let errorCount = 0;
    if (!this.state.amount) {
      this.setState({
        amountError: {
          message: "Ingresa el monto a recargar",
        },
      });
      errorCount++;
    }
    if (this.state.amount > 200000) {
      this.setState({
        amountError: { message: "El monto máximo a recargar es CLP $200.000" },
      });
      errorCount++;
    }
    if (errorCount > 0) {
      return;
    }
    let data = { amount: this.state.amount };

    this.props.getWalletRechargeRequest({
      callback: (result) => this.onResponse(result),
      data: data,
    });
  };

  onResponse = (result) => {
    console.log(result);
    if (result.statusCode == 401 && result.data.error) {
      this.setState({
        showModal: true,
        modalTitle: result.data.error,
        modalIcon: null,
        modalButtonText: "Iniciar Sesión",
        authFailed: true,
        modalButtonTap: this.goToLogin,
        modalBackButtonPress: this.goToHome,
      });
    } else if (result.data && result.data.error) {
      this.refs.toast.show(result.data.error);
      this.setState({
        showModal: true,
        modalTitle: "¡Lo Sentimos!",
        modalBody: result.data.error,
        modalIcon: "/assets/icons/cross-grey.png",
        modalButtonText: "OK",
        modalButtonTap: this.goToMapStepScreen,
        modalBackButtonPress: this.closeModal,
      });
      return;
    } else {
      this.setState({
        showModal: true,
        modalTitle: "¡Casi listo!",
        modalSubtitle: "Tu recarga está en proceso.",
        modalBody:
          "Dirígete a tu punto de recarga más cercano para finalizarla.",
        // modalIcon: "/assets/icons/success.png",
        modalIcon:getIcons('success'),
        modalButtonText: "OK, CONTINUAR",
        modalButtonTap: this.goToMaps,
        modalBackButtonPress: this.closeModal,
      });
    }
  };

  register = () => {
    this.setState({ showModal: false, modalTitle: "", modalBody: "" });
    // CommonService.goToScreen(this.props.componentId,REGISTER_SCREEN,'Regístrate');
  };

  goToHome = () => {
    this.closeModal();
    // Navigation.popToRoot(this.props.componentId);
  };

  goToMaps = () => {
    this.closeModal();
    this.props.history.push("/recharge-wallet/map");
  };

  closeModal = () => {
    this.setState({
      showModal: false,
      modalTitle: "",
      modalBody: "",
      authFailed: false,
      modalButtonText: "",
      modalButtonTap: null,
      modalBackButtonPress: null,
    });
  };

  onTabSelect = (index) => {
    this.setState({
      slectedTab: index + 1,
    });
  };

  goToLogin = () => {
    let authFailed = this.state.authFailed;
    this.closeModal();
    if (authFailed) {
      CommonService.storeLoginDetails(null, this.props.loginSuccess);
      this.props.history.push("/");
      setTimeout(() => {
        this.props.history.push("/login-options");
      }, 150);
    }
  };

  goToMapStepScreen = () => {
    // CommonService.goToScreen(this.props.componentId,RECHARGE_STEPS_SCREEN,'¿Cómo recargar?');
    this.props.history.push("/recharge-wallet/steps");
  };

  getTab1Content = () => {
    return (
      <div style={styles.tabContentContainer}>
        <DescItem
          textLine1="Ingresa el monto que deseas recargar a tu cuenta."
          textLine2=""
        />
        <div className="recharge-input-container">
          <PBCard padding={0}>
            <div className="recharge-body">
              <div
                className="rechrage-input-container"
                key={this.state.amountChanged}
              >
                <PBInput
                  label="Monto a recargar"
                  placeholder="$XXXXX"
                  value={this.state.amount}
                  type="tel"
                  onChange={(text) => this.amountChange(text)}
                  onBlur={(text) => this.onBlur(text, "amount")}
                  error={this.state.amountError ? true : false}
                  errorMessage={
                    this.state.amountError ? this.state.amountError.message : ""
                  }
                ></PBInput>
              </div>
              <div style={styles.buttonContainer}>
                <PButton
                  className={"recharge-submit-button"}
                  title={"RECARGA"}
                  onPress={this.onSubmit}
                />
              </div>
            </div>
          </PBCard>

          <div className="recharge-link-container">
            <div onClick={this.goToMapStepScreen}>
              <PBText fontColor={"primary-font"} textAlign={"text-center"}>
                ¿Cómo recargar?
              </PBText>
            </div>
          </div>
        </div>
      </div>
    );
  };

  getBankDetailsRow = (label, val) => {
    return (
      <div className="recharge-bank-details-row font13">
        <div style={{ width: "48%" }}>
          <div className="dark-font" primary={true}>
            {label}
          </div>
        </div>
        <div style={{ width: "52%" }}>
          <div className="dark-font bold-text">{val}</div>
        </div>
      </div>
    );
  };
///////////////////////////////////////////////////////////////////////

getTab2Content = () => {
  const operator = JSON.parse(localStorage.getItem('op'));
  let bankAccountNumber;
  switch (operator && operator.operator_name) {
    case 'traveltur':
      bankAccountNumber = '20400035335';
      break;
    case 'pullmansanandres':
      bankAccountNumber = '20400035777';
      break;
    case 'cormarbus':
      bankAccountNumber = '20400035653';
      break;
    case 'andimar':
      bankAccountNumber = '20400037885';
      break;
    default:
      bankAccountNumber = '20400001538';
  }

  return (
    <div style={styles.tabContentContainer}>
      <DescItem
        textLine1="Recarga a través de transferencia bancaria."
        textLine2=""
      />
      <div className="tansfer-container">
        <PBCard>
          <div className="recharge-number-card">
            <div className={"font14 dark-font bold-text justify-text"}>
              Transfiere el monto que deseas recargar a la siguiente cuenta
              bancaria:
            </div>
          </div>
          <div className="bank-detail-rows-container">
            {this.getBankDetailsRow(
              "Destinatario:",
              this.props.data.metaData &&
                this.props.data.metaData.bank_info &&
                this.props.data.metaData.bank_info.name
            )}
            {this.getBankDetailsRow(
              "RUT:",
              this.props.data.metaData &&
                this.props.data.metaData.bank_info &&
                this.props.data.metaData.bank_info.rut
            )}
            {this.getBankDetailsRow(
              "Banco:",
              this.props.data.metaData &&
                this.props.data.metaData.bank_info &&
                this.props.data.metaData.bank_info.bank
            )}
            {this.getBankDetailsRow(
              "Cuenta Corriente:",
              bankAccountNumber // Using the determined bank account number here
            )}
            {this.getBankDetailsRow(
              "Correo electrónico:",
              this.props.data.metaData &&
                this.props.data.metaData.bank_info &&
                this.props.data.metaData.bank_info.email
            )}
          </div>
          <hr />
          <div className="recharge-number-card">
            <div className={"font14  bold-text justify-text"} style={{color:operator && operator.color_codes && operator.color_codes.account_text_color}}>
            El saldo será acreditado a la billetera del titular de la cuenta bancaria.
            </div>
          </div>
        </PBCard>
      </div>
    </div>
  );
};


//////////////////////////////////////////////////////////////////////
  // getTab2Content = () => {
  //   const operator = JSON.parse(localStorage.getItem('op'))
  //   return (
  //     <div style={styles.tabContentContainer}>
  //       <DescItem
  //         textLine1="Recarga a través de transferencia bancaria."
  //         textLine2=""
  //       />
  //       <div className="tansfer-container">
  //         <PBCard>
  //           <div className="recharge-number-card">
  //             {/* <div className="step-icon font14 bold-text" style={{backgroundColor:operator && operator.color_codes && operator.color_codes.accounts_bg_color}}>
  //               <PBText fontSize={"font14"} fontColor={"white-font"}>
  //                 1
  //               </PBText>
  //             </div> */}
  //             <div className={"font14 dark-font bold-text justify-text"}>
  //               Transfiere el monto que deseas recargar a la siguiente cuenta
  //               bancaria:
  //             </div>
  //           </div>
  //           <div className="bank-detail-rows-container">
  //             {this.getBankDetailsRow(
  //               "Destinatario:",
  //               this.props.data.metaData &&
  //                 this.props.data.metaData.bank_info &&
  //                 this.props.data.metaData.bank_info.name
  //             )}
  //             {this.getBankDetailsRow(
  //               "RUT:",
  //               this.props.data.metaData &&
  //                 this.props.data.metaData.bank_info &&
  //                 this.props.data.metaData.bank_info.rut
  //             )}
  //             {this.getBankDetailsRow(
  //               "Banco:",
  //               this.props.data.metaData &&
  //                 this.props.data.metaData.bank_info &&
  //                 this.props.data.metaData.bank_info.bank
  //             )}
  //             {this.getBankDetailsRow(
  //               "Cuenta Corriente:",
  //               this.props.data.metaData &&
  //                 this.props.data.metaData.bank_info &&
  //                 this.props.data.metaData.bank_info.acct_no
  //             )}
  //             {this.getBankDetailsRow(
  //               "Correo electrónico:",
  //               this.props.data.metaData &&
  //                 this.props.data.metaData.bank_info &&
  //                 this.props.data.metaData.bank_info.email
  //             )}
  //           </div>
  //           <hr />
  //           <div className="recharge-number-card">
  //             {/* <div
  //               className="step-icon font17 bold-text"
  //               // style={{ animation: "blinking 1s infinite" }}
  //               style={{backgroundColor:operator && operator.color_codes && operator.color_codes.accounts_bg_color}}
  //             >
  //               <PBText fontSize={"font14"} fontColor={"white-font"}>
  //                 2
  //               </PBText>
  //             </div> */}
  //             <div className={"font14  bold-text justify-text"} style={{color:operator && operator.color_codes && operator.color_codes.account_text_color}}>
  //             El saldo será acreditado a la billetera del titular de la cuenta bancaria.
  //             </div>
  //           </div>
  //         </PBCard>
  //       </div>
  //     </div>
  //   );
  // };

  dialCall = () => {
    let phoneNumber = "";

    phoneNumber = "tel:${" + this.props.data.metaData.appPhoneNumber + "}";

    // Linking.openURL(phoneNumber);
  };

  openWhatsapp = () => {
    // Linking.openURL('whatsapp://send?text=hi&phone='+this.props.data.metaData.appPhoneNumber)
  };

  closeCallOut = () => {
    this.props.setMarkerModal({
      show: false,
    });
  };

  renderCustomCallout = () => {
    return (
      <PBModal
        onBackButtonPress={this.closeCallOut}
        onBackdropPress={this.closeCallOut}
        showModal={
          this.props.data.markerModal && this.props.data.markerModal.show
        }
      >
        {this.props.data.markerModal && this.props.data.markerModal.show ? (
          <CustomCallout
            location={this.props.data.markerModal.coords}
            marker={this.props.data.markerModal.marker}
          />
        ) : null}
      </PBModal>
    );
  };

  render() {
    const operator = JSON.parse(localStorage.getItem('op'))
    return (
      <IonPage>
        <IonContent>
          <div style={commonStyles.screenContainer}>
            {/* <PBCarpoolBG short={true} /> */}

            <div className="my-account-header">
              <div
                className="header-back-button"
                onClick={() => this.props.history.push("/")}
              >
                <img
                  className="qr-back-arrow"
                  src="../../assets/carpool/icon_back_arrow.png"
                  alt=''
                />
              </div>
              <div className="my-account-header-label display-flex">
                <span className={"font19 dark-font"}>Recarga tu</span>

                <div className="white-space"></div>
                <span className={"font19 dark-font bold"}>wallet</span>
                {/* <img
                  // src="/assets/svgs/kupos_pay-white.svg"
                  src="/assets/images/cormarpay-logo.jpg"
                  className="kupos-pay-title-logo"
                /> */}
              </div>
              {/* <div
                    className="my-account-flex-end"
                    onClick={() => this.setState({ showLogoutModal: true })}
                  >
                    <img src="../../assets/carpool/icon_logout_white.png" />
                  </div> */}
              <div>&nbsp;</div>
            </div>

            <div>
              {/* {CommonService.isLoggedIn(this.props.data.loginData) && (
                <AccountInfoCard={this.props.data.loginData} />
              )} */}

              <div className="kupos-card recharge-account-card">
                <div>
                  <div className={"bold-text dark-font font14"}>
                    {this.props.data.loginData &&
                      CommonService.capitalize(
                        this.props.data.loginData.name
                      )}{" "}
                    {this.props.data.loginData &&
                    this.props.data.loginData.last_name
                      ? CommonService.capitalize(
                          this.props.data.loginData.last_name
                        )
                      : ""}
                  </div>

                  <div className="display-flex">
                    <PBText fontColor={"dark-font"}>
                      {this.idCardTypes &&
                      this.idCardTypes.find((it) => it.selected)
                        ? this.idCardTypes.find((it) => it.selected).name
                        : ""}
                    </PBText>

                    <PBText fontColor={"dark-font"}>:</PBText>

                    <PBText fontColor={"dark-font"}>
                      {this.props.data.loginData &&
                        this.props.data.loginData.identity_val}
                    </PBText>
                  </div>

                  <PBText fontColor={"dark-font"}>
                  Email : { this.props.data.loginData &&
                      this.props.data.loginData.email}
                  </PBText>
                  <div className="my-account-h-line"></div>

                { operator && operator.operator_name == 'cormarbus' && <div className="display-flex">
                  <div className={"dark-font flex1 bold-text"}>
                    Saldo disponible:
                  </div>
                  <div className={"dark-font bold-text"}>
                    {this.props.data.loginData &&
                      this.props.data.loginData.wallet_moneys &&
                      this.props.data.loginData.wallet_moneys.currency.toUpperCase()}
                  </div>
                  <div className={"dark-font bold-text"}>
                    {" "}
                    {CommonService.currency(
                      this.props.data.loginData &&
                        this.props.data.loginData.wallet_moneys &&
                        this.props.data.loginData.wallet_moneys.amount
                    )}
                  </div>
                </div>}
                </div>
              </div>

              <div className="recharge-tabs-container">
                <PBTabs
                  height={36}
                  // tabsArray={["Efectivo", "Transferencia"]}
                  tabsArray={["Transferencia"]}
                  activeTabColor={colors.primaryBG}
                  onTabSelect={(index) => this.onTabSelect(index)}
                />
              </div>
              {
                // this.state.slectedTab == 1
                //   ? this.getTab1Content()
                //   :
                this.getTab2Content()
              }
              {/* <div className="recharge-bottom-container">
                <PBText style={styles.bottomTitle} bold={true}>
                  ¿Necesitas ayuda?
                </PBText>
                <div className="recharge-contact-numbers-container">
                  <div onPress={this.dialCall}>
                    <div className="recharge-contact-item">
                      <img
                        width={20}
                        style={{ marginRight: 6 }}
                        src="/assets/icons/contact-call.png"
                      />
                      <PBText fontSize={"font16"}>
                        {this.props.data.metaData.appPhoneNumber}
                      </PBText>
                    </div>
                  </div>
                  <div onPress={this.openWhatsapp}>
                    <div className="recharge-contact-item">
                      <img
                        width={20}
                        style={{ marginRight: 6 }}
                        src="/assets/icons/whatsapp-icon.png"
                      />
                      <PBText fontSize={"font16"}>
                        {this.props.data.metaData.appPhoneNumber}
                      </PBText>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            <PBModal
              showModal={this.state.showModal}
              titleText={this.state.modalTitle}
              subtitle={this.state.modalSubtitle}
              bodyText={this.state.modalBody}
              type="alert"
              showButton1={true}
              button1Text={this.state.modalButtonText}
              button1Press={
                this.state.modalButtonTap
                  ? this.state.modalButtonTap
                  : this.closeModal
              }
              icon={this.state.modalIcon}
              onBackButtonPress={
                this.state.modalBackButtonPress
                  ? this.state.modalBackButtonPress
                  : this.goBack
              }
            ></PBModal>
            {this.renderCustomCallout()}
            {/* <Toast ref="toast"/> */}
          </div>
        </IonContent>
      </IonPage>
    );
  }
}

const styles = {
  flex: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  body: {
    paddingVertical: 15,
    paddingHorizontal: 10,
  },
  descContainer: {
    paddingHorizontal: 15,
    marginBottom: 0,
  },
  descText: {
    textAlign: "center",
    fontSize: dimensions.vw * 4,
  },
  inputContainer: {
    paddingVertical: 5,
    paddingHorizontal: 10,
  },
  registerLinkContainer: {
    alignItems: "center",
    justifyContent: "center",
    marginTop: 15,
  },

  // tabs ------
  tabsContainer: {
    width: dimensions.vw * 90,
    marginLeft: dimensions.vw * 5,
    marginVertical: 15,
  },

  linkContainer: {
    marginVertical: 10,
    alignItems: "center",
    justifyContent: "center",
  },

  //tab 2 content
  numberCard: {
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
  },
  numberCardImage: {
    marginRight: 10,
  },
  numberCardText: {
    color: "$textColor",
    fontSize: 14,
  },
  bankDetailsRow: {
    flexDirection: "row",
    paddingHorizontal: 5,
  },

  bottomContainer: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 10,
    marginBottom: 15,
  },

  contactNumbersContainer: {
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 10,
  },
  contactItem: {
    flexDirection: "row",
    justifyContent: "center",
    margin: 10,
  },
  phoneText: {
    fontSize: 16,
  },
};

export default connectData()(RechargeWalletScreen);
